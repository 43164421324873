import { Offer } from "@/features/offers/offersApiSlice";
import { createAppSlice } from "@/store/createAppSlice";
import { resetState } from "@/store/reset";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface PricesSliceState {
  /**
   * The value of the currently selected price. null denotes deslection.
   */
  value: number | null;
  /**
   * An array of offers that have been selected.
   */
  items: Offer[];
  /**
   * Is the offers API response empty? I.e. is it a cold start condition.
   */
  isEmpty: boolean;
  /**
   * The current upper and lower bound of the price list.
   */
  electronicPrices: ElectronicPrices;
}

export interface ElectronicPrices {
  floor: number;
  ceiling: number;
}

const initialState: PricesSliceState = {
  value: null,
  items: [],
  isEmpty: true,
  electronicPrices: {
    floor: 0,
    ceiling: 0,
  },
};

export const pricesSlice = createAppSlice({
  name: "prices",
  initialState,
  reducers: (create) => ({
    setPrice: create.reducer((state, action: PayloadAction<number | null>) => {
      state.value = action.payload;
    }),
    addItem: create.reducer((state, action: PayloadAction<Offer>) => {
      state.items.push(action.payload);
    }),
    removeItem: create.reducer((state, action: PayloadAction<Offer>) => {
      state.items = state.items.filter((i) => i["child-id"] !== action.payload["child-id"]);
    }),
    resetItems: create.reducer((state) => {
      state.items = [];
    }),
    setIsEmpty: create.reducer((state, action: PayloadAction<boolean>) => {
      state.isEmpty = action.payload;
    }),
    setElectronicPrices: create.reducer((state, action: PayloadAction<ElectronicPrices>) => {
      state.electronicPrices = action.payload;
    }),
  }),
  extraReducers: (builder) => {
    builder.addCase(resetState.type, (state) => {
      Object.assign(state, initialState);
    });
  },
  selectors: {
    selectPrice: (state) => state.value || 0,
    selectItems: (state) => state.items,
    selectIsEmpty: (state) => state.isEmpty,
    selectElectronicPrices: (state) => state.electronicPrices,
  },
});

export const { setPrice, addItem, removeItem, resetItems, setIsEmpty, setElectronicPrices } = pricesSlice.actions;

export const { selectPrice, selectItems, selectIsEmpty, selectElectronicPrices } = pricesSlice.selectors;
