import { baseApi } from "@/config/BaseQuery";
import { Direction } from "../orders/parent/parentApiSlice";

export interface Rfq {
  "rfq-request-id": string;
  "request-origin": string;
  "underlying": string;
  "term": string;
  "quantity": number;
  "show-direction": boolean;
  "direction": Direction;
  "created-at": number;
  "seconds-to-expiration": number;
}

export interface CreateRfqPayload {
  "parent-id": string;
  "direction": boolean;
  "quantity": number;
  "broker": string;
  "recipients": string[];
  "duration": string;
}

export interface RfqResponse {
  "rfq-request-id": string;
  "parent-id": string;
  "quantity": number;
  "broker": string;
  "recipients": string[];
  "resolution": string;
  "show-direction": boolean;
}

export interface AcceptRfqPayload {
  "rfq-request-id": string;
  "accepted-client-id": string;
  "residual": boolean;
  "quantity": number;
  "price": number;
}

export interface AcceptRfqResponse {
  "child-id": string;
  "client-id": string;
  "parent-id": string;
  "rfq-request-id": string;
}

const rfqApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getRfqNotifications: build.query<Rfq[], void>({
      query: () => "/rfq/notifications",
      providesTags: ["RfqNotifications"],
      transformResponse: (response: Rfq[], meta, arg) => {
        // Sort response such that those closest to expiration are first.
        return response.sort((a, b) => a["seconds-to-expiration"] - b["seconds-to-expiration"]);
      },
    }),
    createRfq: build.mutation<RfqResponse, CreateRfqPayload>({
      query: (body) => ({
        url: "/rfq/requests",
        method: "PUT",
        body,
      }),
    }),
    acceptRfq: build.mutation<AcceptRfqResponse, AcceptRfqPayload>({
      query: (body) => ({
        url: "/rfq/respond",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["ParentOrders", "InflightChildOrders", "Offers", "RfqNotifications"],
    }),
    dismissRfq: build.mutation<RfqResponse, string>({
      query: (id) => ({
        url: `/rfq/dismiss/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["RfqNotifications"],
    }),
  }),
});

export const { useGetRfqNotificationsQuery, useCreateRfqMutation, useAcceptRfqMutation, useDismissRfqMutation } =
  rfqApiSlice;
