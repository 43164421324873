import { OfferType } from "@/features/offers/offersApiSlice";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { themeColors } from "@/app/theme";
import { metricFormatter } from "@/utils/String";
import usePriceQueries from "@/features/prices/usePriceQueries";

const tickerCircle = (fillColor: string) => {
  return (
    <svg
      width="7"
      height="8"
      viewBox="0 0 7 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: 3, marginBottom: 1 }}
    >
      <circle cx="3.5" cy="4" r="3.5" fill={fillColor} />
    </svg>
  );
};

export default function Ticker() {
  const { offerData, childOrdersData, getBestPrice } = usePriceQueries();

  const allData = offerData && childOrdersData ? [...offerData, ...childOrdersData] : [];

  const bidSum = allData
    .map((offer) => (offer["bid-ask"] === OfferType.BID ? offer.quantity : 0))
    .reduce((cumulativeSum, value) => cumulativeSum + value, 0);

  const askSum = allData
    .map((offer) => (offer["bid-ask"] === OfferType.ASK ? offer.quantity : 0))
    .reduce((cumulativeSum, value) => cumulativeSum + value, 0);

  const bestBid = getBestPrice(OfferType.BID, offerData ?? [], childOrdersData ?? []) ?? 0;

  const bestAsk = getBestPrice(OfferType.ASK, offerData ?? [], childOrdersData ?? []) ?? 0;

  // Placeholder value until API connection is available
  // const lastTradePrice: number = 0;

  return (
    <>
      <Stack direction={"row"} spacing={3}>
        <Stack spacing={-0.5} sx={{ width: 80 }}>
          <Typography color={themeColors.black.primary} fontSize={14}>
            Total Bid
          </Typography>
          <Typography color={themeColors.black.primary} fontSize={24}>
            {bidSum < 1000 ? bidSum.toString().padStart(3, "0") : metricFormatter(bidSum, 1)}
          </Typography>
          <Typography color={themeColors.prices[OfferType.BID]} fontSize={14} fontWeight={600}>
            {tickerCircle(themeColors.prices[OfferType.BID])}
            {bestBid === 0 ? "00000" : bestBid.toFixed(3)}
          </Typography>
        </Stack>
        <Stack spacing={-0.5} sx={{ width: 80 }}>
          <Typography color={themeColors.black.primary} fontSize={14}>
            Total Ask
          </Typography>
          <Typography color={themeColors.black.primary} fontSize={24}>
            {askSum < 1000 ? askSum.toString().padStart(3, "0") : metricFormatter(askSum, 1)}
          </Typography>
          <Typography color={themeColors.prices[OfferType.ASK]} fontSize={14} fontWeight={600}>
            {tickerCircle(themeColors.prices[OfferType.ASK])}
            {bestAsk === 0 ? "00000" : bestAsk.toFixed(3)}
          </Typography>
        </Stack>
        {/* <Stack spacing={-0.5} sx={{ width: 80 }}>
          <Typography color={themeColors.black.primary} fontSize={14}>
            Last Trade
          </Typography>
          <Typography color={themeColors.black.primary} fontSize={24}>
            000
          </Typography>
          <Typography
            color={themeColors.success.primary}
            fontSize={14}
            fontWeight={600}
          >
            {tickerCircle(themeColors.success.primary)}
            {lastTradePrice === 0 ? "00000" : lastTradePrice.toString()}
          </Typography>
        </Stack> */}
      </Stack>
    </>
  );
}
