import { createAppSlice } from "@/store/createAppSlice";
import { resetState } from "@/store/reset";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface InstrumentSliceState {
  instrument: string | null;
  instruments: string[];
}

const initialState: InstrumentSliceState = {
  instrument: null,
  instruments: [],
};

export const instrumentSlice = createAppSlice({
  name: "instrument",
  initialState,
  reducers: (create) => ({
    setInstrument: create.reducer((state, action: PayloadAction<string | null>) => {
      state.instrument = action.payload;
    }),
    setInstruments: create.reducer((state, action: PayloadAction<string[]>) => {
      state.instruments = action.payload;
    }),
  }),
  extraReducers: (builder) => {
    builder.addCase(resetState.type, (state) => {
      Object.assign(state, initialState);
    });
  },
  selectors: {
    selectInstrument: (state) => state.instrument,
    selectInstruments: (state) => state.instruments,
  },
});

export const { setInstrument, setInstruments } = instrumentSlice.actions;

export const { selectInstrument, selectInstruments } = instrumentSlice.selectors;
