import { baseApi } from "@/config/BaseQuery";

enum ClientRoles {
  RiskDesk = "risk-desk",
}

interface ClientsResponse {
  "client-id": string;
  "client-name": string;
}

const clientsApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getRiskDeskClients: build.query<ClientsResponse[], void>({
      query: () => `/clients?role=${ClientRoles.RiskDesk}`,
      providesTags: [{ type: "RiskDeskClients" }],
    }),
  }),
});

export const { useGetRiskDeskClientsQuery } = clientsApiSlice;
