import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ProfileMenu from "@/features/dashboard/components/ProfileMenu";
import NotificationsMenu from "@/features/notifications/components/NotificationsMenu";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { themeColors } from "@/app/theme";
import Logo from "/logo.svg";
import ListItemIcon from "@mui/material/ListItemIcon";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import ArticleIcon from "@mui/icons-material/Article";
import Collapse from "@mui/material/Collapse";
import { useAppSelector } from "@/store/hooks";
import { selectAuthUser } from "@/features/auth/authSlice";
import Ticker from "@/features/dashboard/components/Ticker";

const drawerWidth = 240;

export interface AppBarProps {
  dashboard: boolean;
}

export default function AppBar(props: AppBarProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isUserExpanded, setIsUserExpanded] = useState(true);
  const user = useAppSelector(selectAuthUser);

  const handleUserClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsUserExpanded(!isUserExpanded);
  };

  const handleDrawerToggle = () => {
    setIsDrawerOpen((prevState) => !prevState);
  };

  const navigate = useNavigate();

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box sx={{ p: 1 }}>
        <img style={{ height: 48 }} src={Logo} alt="InTick Logo" />
      </Box>
      <Divider />
      <List>
        {/* Dashboard */}
        <ListItemButton onClick={() => navigate("/dashboard")}>
          <ListItemIcon>
            <SpaceDashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>

        {/* User */}
        <ListItemButton onClick={handleUserClick}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="User" />
          {!isUserExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        {/* User Dropdown */}
        <Collapse in={!isUserExpanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/user/account")}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Account Info" />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
    </Box>
  );

  const container = window !== undefined ? window.document.body : undefined;

  return (
    <>
      <MuiAppBar sx={{ backgroundColor: themeColors.white.primary }} elevation={1}>
        <Toolbar>
          {/* Removed the side drawer button at request of James G. / Sarah / Patrick. */}
          {/* <IconButton
            size="large"
            edge="start"
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}

          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              alignItems: "center",
              cursor: "pointer",
              py: 2,
            }}
            onClick={() => navigate("/dashboard")}
          >
            <img style={{ height: 77 }} src={Logo} alt="InTick Logo" />
          </Box>
          {user && props.dashboard && (
            <>
              <Box sx={{ width: "50vw" }} />
              <Ticker />
            </>
          )}
          <Box sx={{ flexGrow: 1 }} />
          {user && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <NotificationsMenu />
              <ProfileMenu />
            </Box>
          )}
        </Toolbar>
      </MuiAppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={isDrawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </>
  );
}
