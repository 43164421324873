import Box, { BoxProps } from "@mui/material/Box";

export interface ScrollBoxProps extends BoxProps {
  /**
   * A multiple of `8px` of space being occupied above this component.
   * Used to calculate the maximum height of the scrollable box.
   * @example 2
   */
  offset: number;
}

export default function ScrollBox(props: ScrollBoxProps) {
  const baseUnit = 8;
  const appBarOffset = 10 * baseUnit;
  const paddingOffset = 2 * baseUnit;
  const totalOffset = props.offset * baseUnit + appBarOffset + paddingOffset;

  const maxHeight = `calc(100dvh - ${totalOffset}px)`;
  return (
    <Box {...props} sx={{ overflow: "auto", maxHeight }}>
      {props.children}
    </Box>
  );
}
