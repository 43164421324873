import { themeColors } from "@/app/theme";
import AppBar from "@/features/dashboard/components/AppBar";
import { useAppDispatch } from "@/store/hooks";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Navigate, useLocation, useSearchParams } from "react-router-dom";
import {
  fetchUser,
  handlePasswordReset,
  selectAuthErrorCode,
  selectAuthStatus,
  selectAuthToken,
  selectAuthUser,
  selectCallSuccess,
} from "../auth/authSlice";
import ContactFooter from "@/components/ContactFooter";
import { Fade } from "@mui/material";

export default function ChangePassword() {
  const [searchParams] = useSearchParams();
  const username = searchParams.get("username") || "";
  const hash = searchParams.get("hash") || "";
  const isExecutingBrokerClient = searchParams.get("isEBClient") === "true";
  const [password, setPassword] = useState("");
  const [passwordConfirm, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  // The TextFields default to being empty, their error status depends on at least one textfield being empty due to
  // text being erased.
  const isDefaultUsername = useRef(true);
  const isDefaultPassword = useRef(true);
  const isDefaultConfirmPassword = useRef(true);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const location = useLocation();
  const redirect: string = location.state?.from?.pathname || undefined;

  const authToken = useSelector(selectAuthToken);
  const authStatus = useSelector(selectAuthStatus);
  const authUser = useSelector(selectAuthUser);
  const callSuccess = useSelector(selectCallSuccess);

  const dispatch = useAppDispatch();

  const errorStatusCode = useSelector(selectAuthErrorCode);
  const errorMessage =
    errorStatusCode === 403
      ? "Password has already been set, please contact your account administrator."
      : errorStatusCode === 401
      ? "Your session has expired, please login again."
      : authStatus === "failed"
      ? "Invalid username or password, please try again."
      : "";

  useEffect(() => {
    if (authToken && !authUser && authStatus === "idle") {
      // Once the token has been received, fetch the user.
      dispatch(fetchUser(authToken));
    }
  }, [authToken, authUser, authStatus]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement> | React.KeyboardEvent) => {
    event.preventDefault();
    dispatch(
      handlePasswordReset({
        username: username,
        password: password,
        hash: hash,
      })
    );
  };

  const handleEnterPress = (event: React.KeyboardEvent) => {
    if (
      event.key === "Enter" &&
      username.length !== 0 &&
      password.length !== 0 &&
      passwordConfirm.length !== 0 &&
      password === passwordConfirm
    ) {
      handleSubmit(event);
    }
    if (event.key === "Enter" && password !== passwordConfirm) {
      validatePasswords();
    }
  };

  const [redirectAfterDelay, setRedirectAfterDelay] = useState(false);

  const isErrorUsername = !isDefaultUsername.current && username.length === 0;
  const isErrorPassword = !isDefaultPassword.current && password.length === 0 && passwordConfirm.length === 0;
  const isErrorPasswordConfirm =
    !isDefaultPassword.current &&
    password.length === 0 &&
    passwordConfirm.length === 0 &&
    password.toString() != passwordConfirm.toString();

  const validatePasswords = (): boolean => {
    if (password != passwordConfirm) {
      setConfirmPasswordError("Passwords do not match.");
      return false;
    } else if (
      !isDefaultPassword.current &&
      password.length !== 0 &&
      passwordConfirm.length !== 0 &&
      password === passwordConfirm &&
      !validatePasswordChars(password)
    ) {
      setConfirmPasswordError(
        "Passwords must be 10 characters long, contain at least one uppercase letter, one lowercase letter, one special character and one number."
      );
      return false;
    }
    setConfirmPasswordError("");
    return true;
  };

  const validatePasswordChars = (password: string): boolean => {
    // Minimum length of 10 characters
    const lengthPattern = /.{10,}/;

    // At least one uppercase letter
    const uppercasePattern = /[A-Z]/;

    // At least one lowercase letter
    const lowercasePattern = /[a-z]/;

    // At least one special character
    const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;

    return (
      lengthPattern.test(password) &&
      uppercasePattern.test(password) &&
      lowercasePattern.test(password) &&
      specialCharPattern.test(password)
    );
  };

  useEffect(() => {
    if (authToken && authUser && authStatus === "idle") {
      const timer = setTimeout(() => {
        setRedirectAfterDelay(true);
      }, 2000);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [authToken, authUser, authStatus]);

  if (redirectAfterDelay) {
    return <Navigate to={redirect ? (redirect !== "/password" ? redirect : "/dashboard") : "/dashboard"} />;
  }

  return (
    <Container component="main" maxWidth="xs" onKeyUp={handleEnterPress}>
      <AppBar dashboard={false} />
      <Box
        sx={{
          pt: 8,
          mt: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Stack alignItems={"center"} spacing={3}>
          <Stack alignItems={"center"} spacing={1}>
            <Typography component="h1" variant="h4" fontWeight={"bold"}>
              Welcome to Intick
            </Typography>
            <Typography fontWeight={500} fontSize={18}>
              Create a password to activate your account.
            </Typography>
          </Stack>
          <Fade in timeout={3000}>
            <Box>
              <TextField
                margin="normal"
                id="username"
                label="Username"
                name="username"
                disabled
                value={username}
                error={isErrorUsername}
                InputLabelProps={{ shrink: true }}
                size={"small"}
                sx={{
                  "width": 430,
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: isErrorUsername ? themeColors.error.primary : themeColors.active.primary,
                    },
                  },
                }}
              />
              <TextField
                margin="normal"
                name="password"
                label="Create Password"
                id="password"
                value={password}
                autoFocus
                error={isErrorPassword}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(event.target.value);
                  isDefaultPassword.current = false;
                }}
                type={showPassword ? "text" : "password"}
                InputLabelProps={{ shrink: true }}
                size={"small"}
                FormHelperTextProps={{
                  sx: { color: themeColors.error.primary, mt: 1 },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        color="secondary"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "width": 430,
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: isErrorPassword ? themeColors.error.primary : themeColors.active.primary,
                    },
                  },
                }}
              />
              <TextField
                margin="normal"
                name="passwordConfirm"
                label="Repeat Password"
                id="passwordConfirm"
                value={passwordConfirm}
                onFocus={() => {
                  validatePasswords();
                }}
                onKeyUp={() => {
                  validatePasswords();
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setConfirmPassword(event.target.value);
                  isDefaultConfirmPassword.current = false;
                  validatePasswords();
                }}
                onBlur={validatePasswords}
                error={isErrorPasswordConfirm || isErrorPassword}
                type={showPassword ? "text" : "password"}
                InputLabelProps={{ shrink: true }}
                size={"small"}
                helperText={confirmPasswordError || errorMessage || null}
                FormHelperTextProps={{
                  sx: { color: themeColors.error.primary, mt: 1 },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        color="secondary"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "width": 430,
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor:
                        isErrorPasswordConfirm || errorMessage ? themeColors.error.primary : themeColors.active.primary,
                    },
                  },
                }}
              />
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} width={430}>
                  {isExecutingBrokerClient ? null : (
                    <Typography component="p" variant="body1">
                      By signing in, and creating and account you are agreeing to our{" "}
                      <Link target="_blank" rel="noopener noreferrer" to={"https://www.intick.com/terms-of-use"}>
                        Terms of Use
                      </Link>{" "}
                      and{" "}
                      <Link target="_blank" rel="noopener noreferrer" to={"https://www.intick.com/privacy-policy"}>
                        Privacy Policy
                      </Link>
                    </Typography>
                  )}

                  <LoadingButton
                    loading={authStatus === "loading"}
                    disabled={username.length === 0 || password.length === 0 || confirmPasswordError.length !== 0}
                    type="submit"
                    size={"small"}
                    variant="contained"
                    color={callSuccess ? "success" : "rfqDark"}
                    sx={{ width: 120, mt: 3, mb: 2, ml: 14, alignSelf: "flex-end" }}
                  >
                    {/* Span protects against a bug involving google translate */}
                    {callSuccess ? <CheckCircleIcon /> : <span>Done</span>}
                  </LoadingButton>
                </Box>
              </Box>
            </Box>
          </Fade>
        </Stack>
      </Box>
      <ContactFooter />
    </Container>
  );
}
