import Stack from "@mui/material/Stack";
import { themeColors } from "@/app/theme";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

export default function LoginFooter() {
  return (
    <Stack
      component={"footer"}
      direction={"column"}
      alignItems={"center"}
      spacing={2}
      sx={{
        width: "100vw",
        position: "absolute",
        left: 0,
        bottom: 0,
        backgroundColor: themeColors.white.quaternary,
        py: 2,
      }}
    >
      <Stack component={"footer"} direction={"row"} spacing={10} justifyContent={"center"}>
        <Stack alignItems={"center"} spacing={2}>
          <Typography fontWeight={"bold"}>Forgot password?</Typography>
          <Stack spacing={1}>
            <Typography fontSize={12}>
              You can create a new password{" "}
              <Link to={""} color={themeColors.active.primary}>
                here
              </Link>
            </Typography>
          </Stack>
        </Stack>
        <Stack alignItems={"center"} spacing={2}>
          <Typography fontWeight={"bold"}>Don't have an account?</Typography>
          <Stack spacing={1}>
            <Typography fontSize={12}>
              <Link to={""} color={themeColors.active.primary}>
                Sign up
              </Link>{" "}
              to create an account
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Typography fontSize={12}>
        <Link to={""} color={themeColors.active.primary}>
          Contact us
        </Link>{" "}
        if you need assistance
      </Typography>
    </Stack>
  );
}
