/**
 * @param num The number to be formatted.
 * @param digits The number of digits to display after the decimal place.
 * @example metricFormatter(1234, 1) => "1.2K"
 * @example metricFormatter(1234567, 2) => "1.23M"
 */
export function metricFormatter(num: number, digits: number): string {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = lookup.findLast((item) => num >= item.value);
  return item ? (num / item.value).toFixed(digits).replace(regexp, "").concat(item.symbol) : "0";
}

/**
 * @param str The string you want a unique hash for.
 * @returns A number unique(ish) to the input string.
 */
export const generateHash = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash * 17);
  return hash;
};
