import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { useAppDispatch } from "@/store/hooks";
import { handleLogout } from "@/features/auth/authSlice";
import { baseApi } from "@/config/BaseQuery";
import { resetState } from "@/store/reset";

export default function ReAuthLoader() {
  const dispatch = useAppDispatch();

  const onClickLoginBtn = () => {
    // Something went wrong with the re-authentication, so the user
    // is manually going back to the login screen.
    dispatch(handleLogout());
    dispatch(resetState);
    dispatch(baseApi.util.resetApiState());
  };

  return (
    <Card
      sx={{
        maxWidth: 345,
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <LinearProgress />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Please Wait
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Your session has timed out and you are being re-authenticated. If this process takes longer than 10 seconds,
          please go to the login screen.
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={onClickLoginBtn}>
          Login
        </Button>
      </CardActions>
    </Card>
  );
}
