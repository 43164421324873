import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { TabType } from "./NotificationsMenu";
import { themeColors } from "@/app/theme";
import NotificationsListItem from "./NotificationsListItem";
import { Notification } from "../notificationSlice";

export interface TabProps {
  type: TabType;
  notifications?: Notification[];
  isFetching: boolean;
  refetch: () => void;
}

export enum ListItemType {
  ACTIVE = "Active",
  HISTORY = "History",
}

const Header = (props: { title: string }) => {
  return <Typography sx={{ pl: 2, my: 1, borderBottom: 1, borderColor: themeColors.border }}>{props.title}</Typography>;
};

export default function NotificationsTab(props: TabProps) {
  const NotificationsList = (params: { type: ListItemType }) => {
    if (props.notifications?.length) {
      return props.notifications.map((n, i) => (
        <NotificationsListItem
          key={i}
          notification={n}
          type={ListItemType.ACTIVE}
          isFetching={props.isFetching}
          refetch={props.refetch}
        />
      ));
    }
    return (
      <Typography variant="caption" ml={2}>
        {`No ${params.type === ListItemType.ACTIVE ? "active" : "historic"} notifications found.`}
      </Typography>
    );
  };

  return (
    <Stack>
      <Header title={ListItemType.ACTIVE} />
      <NotificationsList type={ListItemType.ACTIVE} />

      {/* Disabled historic notifications as they're not supported by the API yet. */}
      {/* <Header title={ListItemType.HISTORY} />
      <NotificationsList type={ListItemType.HISTORY} /> */}
    </Stack>
  );
}
