import Container from "@mui/material/Container";
import { Outlet, useLocation } from "react-router-dom";
import { useEffect } from "react";
import StickyFooter from "./StickyFooter";

/**
 * The root container that acts as the parent of all the other UI components.
 */
export default function RootLayout() {
  const location = useLocation();

  useEffect(() => {
    // We could do some google analytics here...
    console.log("[ RootLayout ] location changed to:", location.pathname);
  }, [location]);

  return (
    <Container
      maxWidth={false}
      disableGutters
      // sx={{
      //   height: "100dvh",
      //   maxHeight: "100dvh",
      //   display: "flex",
      //   flexDirection: "column",
      //   overflow: "hidden",
      // }}
    >
      {/* This outlet tells react-router where to render any child component. */}
      <Outlet />

      {/* As well as providing a place for useful information to be displayed,
        the StickyFooter gurantees that each page will be >100vh in height, and thus
        there will always be a scroll bar, preventing the page content from jumping
        around if it grows/ shrinks in y. */}
      {/* {location.pathname !== "/login" ? <StickyFooter /> : null} */}
    </Container>
  );
}
