import { baseApi } from "@/config/BaseQuery";

export interface Instrument {
  "underlying-id": string;
  "underlying-name": string;
  "description": string;
  "min-quantity": number;
  "tick-size": number;
}

const instrumentsApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getInstruments: build.query<Instrument[], void>({
      query: () => "/instruments",
      keepUnusedDataFor: 900, // 15 mins.
      providesTags: [{ type: "Instruments", id: "LIST" }],
    }),
  }),
});

export const { useGetInstrumentsQuery } = instrumentsApiSlice;
