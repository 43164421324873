import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { IconButton, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import { round } from "lodash";
import { NumberFormatValues, NumericFormat, OnValueChange, SourceInfo } from "react-number-format";

interface LimitPriceInputProps {
  value: number | null;
  onValueChange: OnValueChange;
  setValue: React.Dispatch<React.SetStateAction<number | null>>;
  disabled: boolean;
  isActionDisabled: boolean;
}

enum ButtonAction {
  Add = "add",
  Subtract = "subtract",
}

const LimitPriceInput = ({ value, setValue, onValueChange, disabled, isActionDisabled }: LimitPriceInputProps) => {
  const handleClickChangeLimitPrice = (action: ButtonAction) => {
    const rounded = action === ButtonAction.Add ? round((value || 0) + 0.001, 3) : round((value || 0) - 0.001, 3);
    if (rounded >= 0) {
      setValue(rounded);
    }
  };

  const handleValueChange = (values: NumberFormatValues, sourceInfo: SourceInfo) => {
    if (values.floatValue !== undefined) {
      setValue(values.floatValue);
    } else {
      setValue(null);
    }
    onValueChange(values, sourceInfo);
  };

  const handleMouseDownLimitPrice = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleFocus = () => {
    if (value === 0) {
      setValue(null);
    }
  };

  const handleBlur = () => {
    if (value === null) {
      setValue(0);
    }
  };

  return (
    <NumericFormat
      value={value ?? ""}
      isAllowed={(values) => !values.floatValue || values.floatValue >= 0}
      onFocus={handleFocus}
      onBlur={handleBlur}
      renderText={(value) => `This is ${value}`}
      customInput={TextField}
      decimalScale={3}
      fixedDecimalScale
      onValueChange={handleValueChange}
      sx={{ width: 180 }}
      size="small"
      id="price-field"
      label="Limit Price"
      disabled={disabled}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              aria-label="reduce limit price"
              color="secondary"
              onClick={() => handleClickChangeLimitPrice(ButtonAction.Subtract)}
              onMouseDown={handleMouseDownLimitPrice}
              edge="start"
              size="small"
              disabled={isActionDisabled}
            >
              <RemoveIcon />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="increase limit price"
              color="secondary"
              onClick={() => handleClickChangeLimitPrice(ButtonAction.Add)}
              onMouseDown={handleMouseDownLimitPrice}
              edge="end"
              size="small"
              disabled={isActionDisabled}
            >
              <AddIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default LimitPriceInput;
