import { baseApi } from "@/config/BaseQuery";
import { OrderState } from "../orders/child/childApiSlice";

export enum OfferType {
  BID = "BID",
  ASK = "ASK",
}

export interface OffersApiResponse {
  inflight: InflightOffer[];
  stats: InflightOfferStats;
}

export interface InflightOfferStats {
  "tick": number;
  "max-price"?: number;
  "min-price"?: number;
}

export interface InflightOffer {
  "id": string;
  "last-updated": string;
}

export interface Offer {
  "child-id": string;
  "parent-id": string;
  "client-name": string;
  "quantity": number;
  "price": number;
  "bid-ask": OfferType;
  "residual": boolean;
  "state": OrderState;
}

const offersApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getOfferIds: build.query<OffersApiResponse, string>({
      query: (arg: string) => `/orders/offer/last-updated/${arg}`,
      providesTags: (result, error, arg) => [{ type: "Offers", id: arg }],
    }),
    getOffers: build.query<Offer[], InflightOffer[]>({
      async queryFn(_arg: any, _queryApi: any, _extraOptions: any, fetchWithBQ: any) {
        if (!_arg.length) {
          return { data: [] };
        }
        const promises = _arg.map((inflightOffer: any) => fetchWithBQ(`/orders/offer/${inflightOffer.id}`));
        const result = await Promise.all(promises);
        const data = result.map((response) => response.data as Offer);
        const errors = result.filter((response) => !!response.error);
        return errors.length ? { error: errors[0]?.error } : { data: data };
      },
    } as any),
  }),
});

export const { useGetOffersQuery, useGetOfferIdsQuery } = offersApiSlice;
