import { themeColors } from "@/app/theme";
import { buildQuery } from "@/config/BaseQuery";
import { useGetEnvironmentQuery } from "@/features/environment/environmentApiSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { DEFAULT_BROKER } from "@/utils/Constants";
import ClearIcon from "@mui/icons-material/Clear";
import LoadingButton from "@mui/lab/LoadingButton";
import Badge from "@mui/material/Badge";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import { NumericFormat, numericFormatter } from "react-number-format";
import { selectAuthToken, selectAuthUser } from "../auth/authSlice";
import { Instrument, useGetInstrumentsQuery } from "../instruments/instrumentsApiSlice";
import { setInstrument } from "../instruments/instrumentSlice";
import { selectActiveRfq, setActiveRfq } from "../notifications/notificationSlice";
import { AcceptRfqPayload, useAcceptRfqMutation } from "../notifications/rfqApiSlice";
import { OfferType } from "../offers/offersApiSlice";
import {
  CreateChildOrderError,
  CreateChildOrderPayload,
  useCreateChildOrderMutation,
} from "../orders/child/childApiSlice";
import { MatchRequestPayload, useRequestMatchMutation } from "../orders/match/matchApiSlice";
import { Direction, ParentOrder } from "../orders/parent/parentApiSlice";
import { selectParent, setParentOrder } from "../orders/parent/parentSlice";
import {
  resetItems,
  selectElectronicPrices,
  selectIsEmpty,
  selectItems,
  selectPrice,
  setPrice,
} from "../prices/pricesSlice";
import ErrorLabel from "./components/ErrorLabel";
import LimitPriceInput from "./components/LimitPriceInput";
import RfqDetails from "./components/RfqDetails";
import { Residual } from "./types";

const SummaryBox: React.FC = () => {
  const dispatch = useAppDispatch();

  const selectedPrice = useAppSelector(selectPrice);
  const selectedItems = useAppSelector(selectItems);
  const selectedParent = useAppSelector(selectParent);
  const electronicPrices = useAppSelector(selectElectronicPrices);
  const isOffersResponseEmpty = useAppSelector(selectIsEmpty);
  const user = useAppSelector(selectAuthUser);
  const authToken = useAppSelector(selectAuthToken);
  const activeRfq = useAppSelector(selectActiveRfq);

  const [total, setTotal] = useState(0);
  const [minBlockSize, setMinBlockSize] = useState(0);
  const [isResidualOk, setIsResidualOk] = useState<Residual>(Residual.NO);
  const [tier, setTier] = useState(99);

  const [limitPrice, setLimitPrice] = useState<number | null>(selectedPrice);

  const isPassive = !selectedItems.length;
  const isWarmStart = !!selectedParent && !!selectedPrice;
  const isColdStart = !!selectedParent && isOffersResponseEmpty;
  const isRfq = activeRfq !== null;
  const remainingTotal = selectedParent?.["parent-order-aggregation"]?.["remaining-total"];
  const isWorkingOrderSelected =
    selectedItems.length === 1 &&
    !!selectedParent &&
    (selectedItems as any)[0]["parent-id"] === selectedParent["parent-id"];

  const [isOpen, setIsOpen] = useState(
    ((isWarmStart || isColdStart || isRfq) && remainingTotal !== 0) || isWorkingOrderSelected
  );

  useEffect(() => {
    setIsOpen(((isWarmStart || isColdStart || isRfq) && remainingTotal !== 0) || isWorkingOrderSelected);
    if (isWorkingOrderSelected) {
      setIsResidualOk(selectedItems[0]?.residual ? Residual.OK : Residual.NO);
    }
  }, [isWarmStart, isColdStart, isRfq, remainingTotal, selectedItems, selectedParent]);

  // Default for RFQ response.
  useEffect(() => {
    if (!!activeRfq) {
      setIsResidualOk(Residual.OK);
    }
  }, [activeRfq]);

  const [
    createChildOrder,
    {
      error: createChildOrderError,
      isLoading: isLoadingCreateChildOrder,
      isSuccess: isSuccessCreateChildOrder,
      isError: isErrorCreateChildOrder,
    },
  ] = useCreateChildOrderMutation();

  const [
    requestMatch,
    {
      error: requestMatchError,
      isLoading: isLoadingRequestMatch,
      isSuccess: isSuccessRequestMatch,
      isError: isErrorRequestMatch,
    },
  ] = useRequestMatchMutation();

  const [
    acceptRfq,
    { error: acceptRfqError, isLoading: isLoadingAcceptRfq, isSuccess: isSuccessAcceptRfq, isError: isErrorAcceptRfq },
  ] = useAcceptRfqMutation();

  const {
    data: instrumentsData,
    error: instrumentsError,
    isError: isErrorInstruments,
    isFetching: isFetchingInstruments,
    isSuccess: isSuccessInstruments,
  } = useGetInstrumentsQuery();

  const { data: envData, error: envError, isError: isErrorEnv } = useGetEnvironmentQuery();

  const [instrument, setInstruemnt] = useState<Instrument | null>(null);

  useEffect(() => {
    if (instrumentsData) {
      const instrument =
        instrumentsData.find(
          (i) => i["underlying-name"] === selectedParent?.underlying || i["underlying-name"] === activeRfq?.underlying
        ) || null;

      setInstruemnt(instrument);
      if (instrument) {
        setMinBlockSize(instrument["min-quantity"]);
      }
    }
  }, [selectedParent, instrumentsData, activeRfq]);

  useEffect(() => {
    if (!!selectedItems.length) {
      const selectedItemsTotal = selectedItems
        .map((item) => item.quantity)
        .reduce((total, quantity) => total + quantity, 0);

      setTotal(selectedItemsTotal);
      return;
    }

    if (activeRfq !== null) {
      // When an RFQ is active set the summary box quantity to its quantity
      setTotal(activeRfq.quantity);
    } else {
      // When an RFQ is no longer active, reset the summary box quantity
      if (remainingTotal !== undefined && remainingTotal > 0) {
        // By default, set the quantity of a passive trade to equal the
        // remaining quantity of a parent.
        setTotal(remainingTotal);
      } else {
        setTotal(0);
      }
    }
  }, [selectedItems, activeRfq, remainingTotal]);

  const handleChangeResidualOk = (event: SelectChangeEvent) => {
    setIsResidualOk(event.target.value as Residual);
  };

  // const handleChangeTier = (event: SelectChangeEvent) => {
  //   setTier(parseInt(event.target.value));
  // };

  // If the summary box closes (i.e. by deselecting a price), the default
  // tier should be reset to 1.
  // Likewise isResidualOk should be reset to "No" (if not responding to an RFQ).
  useEffect(() => {
    if (!isOpen) {
      setTier(99);
      setIsResidualOk(isRfq ? Residual.OK : Residual.NO);
    }
  }, [isOpen]);

  // If the user selects a price card, update the value being tracked here.
  useEffect(() => {
    setLimitPrice(selectedPrice);
  }, [selectedPrice]);

  const handleSubmit = async () => {
    if (isRfq) {
      handleSubmitRfq();
      return;
    }
    if (!selectedParent || !selectedPrice) return;
    if (!selectedItems.length) {
      // Create a passive offer (a.k.a. a "working" child order)
      const payload: CreateChildOrderPayload = {
        "parent-id": selectedParent["parent-id"],
        "quantity": total,
        "price": selectedPrice,
        "residual": isResidualOk === Residual.OK,
        "tier": tier,
      };
      try {
        const response = await createChildOrder(payload).unwrap();
      } catch (error) {
        console.log("[ SummaryBox ] handleSubmit (passive) Error: ", error);
      }
    } else {
      // Aggresively take one or more offers (a.k.a. create "waiting" child orders)
      const payloads: MatchRequestPayload[] = selectedItems.map((item) => {
        return {
          "client-id": user?.["client-id"] || "",
          "child-id": item["child-id"],
          "requesting-parent-id": selectedParent["parent-id"],
          "quantity": item.quantity,
        };
      });
      try {
        const promises = payloads.map((payload) => requestMatch(payload));
        await Promise.all(promises);
      } catch (error) {
        console.log("[ SummaryBox ] handleSubmit (aggresive) Error: ", error);
      } finally {
        resetGlobalPrice(); // Deselect the price.
        dispatch(resetItems()); // Deselect all offers.
      }
    }
  };

  const handleSubmitRfq = async () => {
    if (!isRfq || !user || selectedPrice === null) return;
    try {
      const payload: AcceptRfqPayload = {
        "rfq-request-id": activeRfq["rfq-request-id"],
        "accepted-client-id": user["client-id"],
        "residual": isResidualOk === Residual.OK,
        "quantity": total,
        "price": selectedPrice,
      };
      const response = await acceptRfq(payload).unwrap();

      // Automatiacally fetch the newly created parent order and select it.
      const newParentResponse = await axios.get<ParentOrder>(buildQuery(`/orders/parent/${response["parent-id"]}`), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (newParentResponse.data) {
        setTimeout(() => {
          // Timeout is necessary because the ParentOrders component needs to
          // fetch all of the parent order ids first.
          dispatch(setParentOrder(newParentResponse.data));
        }, 500);
      }
    } catch (error) {
      console.log("[ SummaryBox ] acceptRfq error", error);
    } finally {
      resetGlobalPrice();
      dispatch(setActiveRfq(null));
    }
  };

  useEffect(() => {
    // Update the redux store with the limit price - this enables price ladder
    // updates if there are no offers in the market.
    dispatch(setPrice(limitPrice));
  }, [limitPrice]);

  const handleDismissRfq = () => {
    if (isRfq) {
      dispatch(setActiveRfq(null));
      if (!selectedParent) {
        dispatch(setInstrument(null));
      }
      setLimitPrice(null);
    }
  };

  // const selectIsPriceInputDisabled = () => {
  //   if (!selectedItems.length) {
  //     return false;
  //   }
  //   if (selectedItems[0]?.state !== OrderState.Working) {
  //     return true;
  //   }
  //   return false;
  // };
  const resetGlobalPrice = () => {
    dispatch(setPrice(null));
  };

  const selectErrorMessage = () => {
    if (isWorkingOrderSelected) return null;
    const error = createChildOrderError || requestMatchError || undefined;
    if (error && "data" in error) {
      const messages = (error.data as CreateChildOrderError)?.errors || [];
      // Grab the first error message, or use the default.
      const message = !!messages.length ? capitalize(messages[0]) : "An unknown error occurred.";
      return message;
    }

    if (getIsTotalInvalid()) {
      return "Quantity exceeds the remaining quantity on the order.";
    }

    if (instrument && total < instrument["min-quantity"]) {
      return `The minimum block size is ${numericFormatter(instrument["min-quantity"].toString(), {
        thousandSeparator: true,
      })}.`;
    }

    return null;
  };

  const getButtonColour = () => {
    // Confirmation button colour should match the offer colour.
    const direction = activeRfq?.direction || selectedParent?.direction;
    if (direction === undefined) return "dark";
    return direction === Direction.BUY ? OfferType.BID : OfferType.ASK;
  };

  const getIsTotalInvalid = () => {
    if (remainingTotal !== undefined) {
      return total > remainingTotal;
    }
    // Not sure if this is a requirement or not.
    // if (isRfq) {
    //   return total > activeRfq.quantity;
    // }
    return false;
  };

  const termStart = envData?.["rolling-term-start"] ?? "";
  const termEnd = envData?.["rolling-term-end"] ?? "";

  const activeUnderlying = capitalize(selectedParent?.underlying ?? activeRfq?.underlying ?? "");
  const activeDirection = selectedParent?.direction ?? activeRfq?.direction ?? "";

  const activeQuantity = numericFormatter(total.toString(), {
    thousandSeparator: true,
  });
  const errorMessage = selectErrorMessage();
  const isFormValid = () => {
    if (total < minBlockSize || getIsTotalInvalid()) {
      return false;
    }
    if (limitPrice === null || limitPrice >= electronicPrices.ceiling || limitPrice <= electronicPrices.floor) {
      return false;
    }

    return true;
  };

  if (isOpen && !!user) {
    return (
      <Paper
        elevation={4}
        component="form"
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          handleSubmit();
        }}
        sx={{
          minWidth: !!activeRfq ? "60vw" : "50vw",
          position: "fixed",
          bottom: 0,
          right: 0,
          backgroundColor: themeColors.white.primary,
          borderTop: `1px solid ${themeColors.border}`,
          borderRadius: "8px 0 0 8px",
        }}
      >
        <Stack direction="row" justifyContent="space-between" sx={{ pr: 2 }}>
          <Stack direction="row">
            {!!activeRfq && <RfqDetails />}
            <Stack sx={{ py: 3, pl: 2 }}>
              <Typography variant="h6" lineHeight={"130%"}>
                {activeUnderlying}
              </Typography>
              <Typography variant={"body1"} lineHeight={"130%"}>
                {termStart}
                {termEnd}
              </Typography>
              {activeDirection !== "" && (
                <Typography fontSize={10} fontWeight={500} lineHeight={"130%"} letterSpacing={"0.5px"} mt={1}>
                  {capitalize(activeDirection)} {activeQuantity} {activeUnderlying} {termStart} <br />
                  {capitalize(Object.values(Direction).find((value) => value !== activeDirection))} {activeQuantity}{" "}
                  {activeUnderlying} {termEnd}
                </Typography>
              )}
            </Stack>
          </Stack>

          <Stack spacing={2} mr={1} alignItems="flex-end" py={3}>
            {/* Top row */}
            <Stack direction="row" height={40} spacing={1}>
              <NumericFormat
                // NumericFormat Props
                value={total}
                customInput={TextField}
                allowNegative={false}
                thousandSeparator
                onValueChange={(values, sourceInfo) => {
                  if (values.floatValue !== undefined) {
                    setTotal(values.floatValue);
                  } else {
                    setTotal(0);
                  }
                }}
                // MUI TextField Props
                sx={{ width: 128 }}
                disabled={selectedItems?.length !== 0}
                size="small"
                id="total-field"
                label="Quantity"
                error={total < minBlockSize || getIsTotalInvalid()}
              />
              <LimitPriceInput
                value={limitPrice}
                setValue={setLimitPrice}
                onValueChange={(values) => {
                  if (values.floatValue !== undefined) {
                    if (!isColdStart && !isRfq) {
                      setLimitPrice(values.floatValue);
                    } else {
                      // TODO: this currently allows users to enter prices
                      // beyond the electronic tick when responding to an RFQ.
                      setLimitPrice(values.floatValue);
                    }
                  }
                }}
                disabled={!!selectedItems.length}
                isActionDisabled={isFetchingInstruments || !!selectedItems.length}
              />
              {(isPassive || isWorkingOrderSelected) && (
                <FormControl size="small" sx={{ width: 96 }}>
                  <InputLabel id="select-residual-label">Residual</InputLabel>
                  <Select
                    labelId="select-residual-label"
                    id="select-residual"
                    value={isResidualOk}
                    label="Residual"
                    disabled={isWorkingOrderSelected}
                    MenuProps={{ disablePortal: true }}
                    onChange={handleChangeResidualOk}
                  >
                    <MenuItem value={Residual.NO}>No</MenuItem>
                    <MenuItem value={Residual.OK}>OK</MenuItem>
                  </Select>
                </FormControl>
              )}
              {!isWorkingOrderSelected && (
                <Badge
                  badgeContent={selectedItems.length}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  color="success"
                >
                  <LoadingButton
                    loading={isLoadingCreateChildOrder || isLoadingRequestMatch}
                    size="small"
                    variant="contained"
                    color={getButtonColour()}
                    sx={{ fontSize: 18, borderRadius: 2 }}
                    disabled={!isFormValid()}
                    data-testid="submit-child-order"
                    type="submit"
                  >
                    <span>
                      {activeRfq !== null
                        ? capitalize(activeRfq?.direction ?? "Submit")
                        : capitalize(selectedParent?.direction ?? "Submit")}
                    </span>
                  </LoadingButton>
                </Badge>
              )}
              {isRfq && (
                <IconButton
                  onClick={() => handleDismissRfq()}
                  sx={{
                    height: 24,
                    width: 24,
                    color: themeColors.text.primary,
                  }}
                >
                  <ClearIcon
                    sx={{
                      height: 16,
                      width: 16,
                      color: themeColors.text.primary,
                    }}
                  />
                </IconButton>
              )}
            </Stack>

            {/* Bottom Row */}
            {!isRfq && (
              <Stack direction="row" height={40} spacing={1}>
                {/* {isPassive && (
                  <FormControl size="small" sx={{ width: 128 }}>
                    <InputLabel id="select-tier-label">Tier</InputLabel>
                    <Select
                      labelId="select-tier-label"
                      id="select-tier"
                      value={tier.toString()}
                      label="Tier"
                      MenuProps={{ disablePortal: true }}
                      onChange={handleChangeTier}
                    >
                      <MenuItem value={99}>All</MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                    </Select>
                  </FormControl>
                )} */}
                {(isPassive || isWorkingOrderSelected) && (
                  <>
                    <TextField
                      sx={{ width: 128 }}
                      disabled
                      size="small"
                      id="broker-field"
                      label="Broker"
                      value={DEFAULT_BROKER}
                      // value={user["default-broker"]}
                    />
                    <TextField sx={{ width: 96 }} disabled size="small" id="tif-field" label="TIF" value="Day" />
                  </>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
        <ErrorLabel message={errorMessage} />
      </Paper>
    );
  }

  return null;
};

export default SummaryBox;
