import type { PayloadAction } from "@reduxjs/toolkit";
import { createAppSlice } from "@/store/createAppSlice";
import { resetState } from "@/store/reset";
import { MatchRequest } from "./matchRequestApiSlice";
import { Rfq } from "./rfqApiSlice";
import { capitalize } from "lodash";
import { metricFormatter } from "@/utils/String";
import { Direction } from "../orders/parent/parentApiSlice";

export type Notification = MatchRequest | Rfq | null;

export interface NotificationSliceState {
  /**
   * The notification currently being viewed in detail.
   * Set when selecting the "view" button in NotificationsListItem.tsx.
   */
  notification: Notification;
  /**
   * The current RFQ being responded to.
   * Set when selecting the "accept" button in NotificationDetails.tsx.
   */
  activeRfq: Rfq | null;
}

const initialState: NotificationSliceState = {
  notification: null,
  activeRfq: null,
};

export const notificationSlice = createAppSlice({
  name: "notification",
  initialState,
  reducers: (create) => ({
    setActiveNotification: create.reducer((state, action: PayloadAction<Notification>) => {
      state.notification = action.payload;
    }),
    setActiveRfq: create.reducer((state, action: PayloadAction<Rfq | null>) => {
      state.activeRfq = action.payload;
    }),
  }),
  extraReducers: (builder) => {
    builder.addCase(resetState.type, (state) => {
      Object.assign(state, initialState);
    });
  },
  selectors: {
    selectActiveNotification: (state) => state.notification,
    selectActiveRfq: (state) => state.activeRfq,
  },
});

export const { setActiveNotification, setActiveRfq } = notificationSlice.actions;

export const { selectActiveNotification, selectActiveRfq } = notificationSlice.selectors;

export const isMatchRequest = (n: Notification) => {
  return n !== null && "match-request-id" in n;
};

export const isRfq = (n: Notification) => {
  return n !== null && "rfq-request-id" in n;
};

export const getNotificationTitle = (n: Notification) => {
  if (isMatchRequest(n)) {
    return "Match for approval";
  }
  if (isRfq(n)) {
    return `RFQ: ${capitalize(n.underlying)}`;
  }
  return "Unknown notification type";
};

export const getNotificationSubtitle = (n: Notification) => {
  if (isMatchRequest(n)) {
    return "Accept this match for your working order.";
  }
  if (isRfq(n)) {
    return `This request is for ${n.term} for the ${capitalize(n.underlying)}.`;
  }
  return "Please contact a member of the InTick team.";
};

export const getNotificationBody = (n: Notification): string => {
  if (isMatchRequest(n)) {
    return `${capitalize(n.side)} ${metricFormatter(n.offering.quantity, 1)} @ ${n.price}`;
  }
  if (isRfq(n)) {
    return getNotificationSubtitle(n);
  }
  return "Please contact a member of the InTick team.";
};

export const getNotificationCaption = (n: Notification): string => {
  if (isRfq(n)) return getNotificationSender(n);
  else return "";
};

export const getNotificationSender = (n: Notification): string => {
  if (isMatchRequest(n)) {
    return `Client: ${n.requesting["client-name"]}`;
  }
  if (isRfq(n)) {
    return `Client: ${n["request-origin"]}`;
  }
  return "Client: -";
};

/**
 * Reverses the direction sent by the API in order to reflect the direction
 * of the parent order that created the RFQ request.
 */
export const getRfqDirection = (rfq: Notification): string => {
  if (!isRfq(rfq) || !rfq.direction || !rfq["show-direction"]) return "-";
  return rfq.direction === Direction.BUY ? Direction.SELL : Direction.BUY;
};
