import theme, { themeColors } from "@/app/theme";
import { selectActiveRfq } from "@/features/notifications/notificationSlice";
import { Offer, OfferType } from "@/features/offers/offersApiSlice";
import { Direction } from "@/features/orders/parent/parentApiSlice";
import { selectParent } from "@/features/orders/parent/parentSlice";
import EditAmountDialog from "@/features/prices/components/EditAmountDialog";
import { addItem, removeItem, resetItems } from "@/features/prices/pricesSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { metricFormatter } from "@/utils/String";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import styled from "@mui/system/styled";
import { useEffect, useState } from "react";
import { numericFormatter } from "react-number-format";

export interface OfferCardProps {
  offer: Offer;
  isPriceSelected: boolean;
  onClick: (price: number) => void;
}

export default function OfferCard(props: OfferCardProps) {
  const selectedParent = useAppSelector(selectParent);
  const activeRfq = useAppSelector(selectActiveRfq);
  const dispatch = useAppDispatch();
  const [isSelected, setIsSelected] = useState(false);

  const isWorkingOrder = selectedParent?.["parent-id"] === props.offer["parent-id"];

  const [offerQuantity, setOfferQuantity] = useState(props.offer.quantity);

  const isSelectable =
    (!!selectedParent &&
      activeRfq === null &&
      (selectedParent.direction === Direction.BUY
        ? props.offer["bid-ask"] === OfferType.ASK
        : props.offer["bid-ask"] === OfferType.BID)) ||
    isWorkingOrder;

  const isRfqResponse = !!selectedParent?.["rfq-client-name"] && isWorkingOrder;

  const isEditable = isSelectable && props.offer["parent-id"] !== selectedParent?.["parent-id"];

  useEffect(() => {
    if (!props.isPriceSelected || !!activeRfq) {
      // Force deslection if deselcted the price or responding to an RFQ.
      setIsSelected(false);
    }
  }, [props.isPriceSelected, activeRfq]);

  useEffect(() => {
    const offer: Offer = { ...props.offer, quantity: offerQuantity };
    if (isSelected) {
      if (isWorkingOrder) {
        // First clear out any other selected items, before selecting
        // a Working order.
        dispatch(resetItems());
      }
      dispatch(addItem(offer));
    } else {
      dispatch(removeItem(offer));
    }
  }, [isSelected]);

  const CustomInput = styled(TextField)(({ theme }) => ({
    "input": {
      cursor: isSelectable ? "pointer" : "default",
      fontWeight: 500,
      fontSize: isSelected ? 16 : 18,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: 0,
      },
      "&:hover fieldset": {
        border: 0,
      },
      "&.Mui-focused fieldset": {
        border: 0,
      },
    },
    "& .MuiFormLabel-root": {
      backgroundColor: isSelected
        ? themeColors.success.secondary
        : isRfqResponse
        ? themeColors.state[props.offer.state]
        : themeColors.white.primary,
      borderRadius: "4px 4px 0 0",
      padding: "0 4px",
      // marginTop: "-10px",
      zIndex: 0, // Stops the labels appearing over the summary box
    },
    "& .MuiInputBase-input": {
      padding: isEditable && isSelected ? "16px 16px 12px 24px" : "16px 16px 16px 24px",
    },
  }));

  const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    "&.MuiAccordionSummary-root": {
      "&.Mui-focusVisible": {
        backgroundColor: "inherit",
        borderRadius: "0.5rem",
      },
      "&.MuiButtonBase-root": {
        cursor: isSelectable ? "pointer" : "default",
      },
    },
  }));

  const [amountDialogOpen, setAmountDialogOpen] = useState(false);

  useEffect(() => {
    if (amountDialogOpen) {
      // Reset the quantity to the full amount each time the dialog is opened.
      setOfferQuantity(props.offer.quantity);
    }
  }, [amountDialogOpen]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (isSelectable) {
      setIsSelected(!isSelected);
      if (!props.isPriceSelected) {
        // Allows us to directly select an offer (before selecting the price).
        props.onClick(props.offer.price);
      }
    }
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAmountDialogOpen(true);
  };

  const handleCloseAmountDialog = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setAmountDialogOpen(false);
  };

  const customInputProps = {
    label: isRfqResponse
      ? `Client: ${selectedParent["rfq-client-name"]}`
      : isWorkingOrder
      ? ""
      : props.offer["client-name"] || "",
    defaultValue: !isSelected
      ? metricFormatter(offerQuantity, 1)
      : numericFormatter(offerQuantity.toString(), {
          thousandSeparator: true,
        }),
    onClick: handleClick,
  };

  return (
    <Box sx={{ position: "relative", width: 200 }}>
      <Accordion
        elevation={0}
        expanded={isSelected && !isWorkingOrder}
        sx={{
          "boxShadow": 1,
          "border": 1,
          "borderColor": isSelected
            ? isWorkingOrder
              ? themeColors.black.primary
              : themeColors.success.primary
            : theme.palette[props.offer["bid-ask"]].main,
          "p": 0,
          "&:hover": {
            borderColor: theme.palette[props.offer["bid-ask"]].dark,
          },
          "backgroundColor": isSelected
            ? isWorkingOrder
              ? themeColors.active.secondary
              : themeColors.success.secondary
            : isWorkingOrder
            ? themeColors.state.WORKING
            : themeColors.white.primary,
        }}
        disableGutters
        onClick={handleClick}
      >
        <CustomAccordionSummary
          aria-controls="price-content"
          id="price-header"
          sx={{
            "p": 0,
            ".MuiAccordionSummary-content": {
              margin: 0,
              // alignItems: "center",
            },
          }}
        >
          {/* Offer Quantity */}
          <CustomInput
            {...customInputProps}
            inputProps={{
              readOnly: true,
            }}
          />
          {/* Residual & Hit Rate */}
          <Stack direction="row" sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleClick}>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                mr: 1,
                // height: "16px",
                backgroundColor: "border",
              }}
            />
            <Stack spacing={1}>
              <Typography sx={{ fontSize: 11 }}>{`Residual: ${props.offer.residual ? "OK" : "No"}`}</Typography>
              <Typography sx={{ fontSize: 11 }}>
                {/* TODO: Defaulted to 100% hit rate for now */}
                {isRfqResponse ? "Auto-accept" : "Hit Rate: 100%"}
              </Typography>
            </Stack>
          </Stack>
        </CustomAccordionSummary>
        <AccordionDetails
          sx={{
            pt: 0,
            pb: 0.5,
            px: 0,
            cursor: isSelectable ? "pointer" : "default",
          }}
        >
          <Stack>
            <Box sx={{ px: 2 }}>
              {isEditable ? (
                <Button
                  sx={{
                    alignSelf: "flex-start",
                    fontSize: 11,
                    fontWeight: "bold",
                    textDecoration: "underline",
                    p: 0.5,
                    ml: 0.5,
                  }}
                  onClick={handleButtonClick}
                >
                  Edit amount
                </Button>
              ) : null}
              <EditAmountDialog
                open={amountDialogOpen}
                onClose={handleCloseAmountDialog}
                offer={props.offer}
                offerQuantity={offerQuantity}
                setOfferQuantity={setOfferQuantity}
                isOfferSelected={isSelected}
              />
              <Divider sx={{ backgroundColor: "border" }} />
            </Box>
            <Typography sx={{ fontSize: 9, textAlign: "center" }}>Accept Time: 0 mins</Typography>
            <Typography sx={{ fontSize: 9, textAlign: "center" }}>Median Accept Time: 0 mins</Typography>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
