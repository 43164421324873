import { themeColors } from "@/app/theme";
import { useGetEnvironmentQuery } from "@/features/environment/environmentApiSlice";
import {
  selectInstrument,
  selectInstruments,
  setInstrument,
  setInstruments,
} from "@/features/instruments/instrumentSlice";
import FlagIcon from "@/features/orders/parent/components/FlagIcon";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";

const FLAG_HEIGHT = 24;

export default function UnderlyingSelect() {
  const selectedInstrument = useAppSelector(selectInstrument);
  const instruments = useAppSelector(selectInstruments);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setInstruments([
        "Euro Stoxx 50",
        "Bund",
        "Euro Stoxx Banks",
        "Stoxx Europe 600",
        "SMI",
        "Bobl",
        "Schatz",
        "BTP",
        "OAT",
        "Short BTP",
        "Buxl",
      ])
    );
  }, []);
  const [selectValue, setSelectValue] = useState(selectedInstrument || "");

  // Get environment details to get access to the instrument term
  const { data: envData, error: envError, isError: isErrorEnv } = useGetEnvironmentQuery();
  const handleChange = (event: SelectChangeEvent) => {
    setSelectValue(event.target.value);
    dispatch(setInstrument(event.target.value || null));
  };
  const termDates = `${envData?.["rolling-term-start"] ?? ""}${envData?.["rolling-term-end"] ?? ""}`;
  const TruncatedTypography = styled(Typography)({
    fontSize: 14,
    ml: 1,
    width: 180,
    overflow: "hidden",
    textOverflow: "ellipsis",
  });
  return (
    <Box sx={{ display: "flex", justifyContent: "end", mb: 1, mr: 7 }}>
      <FormControl sx={{ width: 224 }}>
        <Select
          labelId="underlying-select-label"
          id="underlying-select"
          size="small"
          displayEmpty
          value={selectValue}
          onChange={handleChange}
          sx={{
            width: 256,
            borderRadius: 2,
            backgroundColor: themeColors.white.primary,
          }}
          MenuProps={{
            PaperProps: {
              sx: { width: 256 },
            },
          }}
        >
          <MenuItem value={""}>
            <Typography fontSize={14}>Select to view instruments</Typography>
          </MenuItem>
          {instruments.map((instrument) => (
            <MenuItem value={instrument} key={instrument}>
              <Stack direction="row" spacing={1} alignItems="center">
                <FlagIcon height={FLAG_HEIGHT} underlying={instrument} />
                <TruncatedTypography>{`${instrument} ${termDates}`}</TruncatedTypography>
              </Stack>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
