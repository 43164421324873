import { useState } from "react";

/**
 * Reusable logic for MUI Menu components.
 */
export function useMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return { anchorEl, isMenuOpen, handleMenuOpen, handleMenuClose };
}
