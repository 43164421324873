import { selectAuthUser } from "@/features/auth/authSlice";
import { DEFAULT_BROKER } from "@/utils/Constants";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppBar from "./AppBar";

export default function AccountInfo() {
  const navigate = useNavigate();

  const user = useSelector(selectAuthUser);
  if (!user) return null;

  return (
    <>
      <AppBar dashboard={false} />
      <Grid component="main" container spacing={2} sx={{ mt: 14 }}>
        <Grid item xs={4}>
          <Stack spacing={2} px={4}>
            <TextField
              id="account-info-username"
              label="Username"
              defaultValue={user.username}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              id="account-info-full-name"
              label="Full Name"
              defaultValue={user["full-name"]}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              id="account-info-user-id"
              label="User ID"
              defaultValue={user["user-id"]}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              id="account-info-email-address"
              label="Email Address"
              defaultValue={user["email-address"]}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              id="account-info-client-name"
              label="Client Name"
              defaultValue={user["client-name"]}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              id="account-info-client-full-name"
              label="Client Full Name"
              defaultValue={user["client-full-name"]}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              id="account-info-client-id"
              label="Client ID"
              defaultValue={user["client-id"]}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              id="account-info-default-broker"
              label="Default Broker"
              // defaultValue={user["default-broker"]}
              defaultValue={DEFAULT_BROKER}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              id={`account-info-broker-${DEFAULT_BROKER}`}
              label="Connected Broker"
              defaultValue={DEFAULT_BROKER}
              InputProps={{
                readOnly: true,
              }}
            />

            {/* {user.brokers.length ? (
              user.brokers.map((broker, i) => (
                <TextField
                  key={i}
                  id={`account-info-broker-${broker}`}
                  label="Connected Broker"
                  defaultValue={broker}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              ))
            ) : (
              <Typography variant="caption" pl={2}>
                No other connected brokers.
              </Typography>
            )} */}
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <Stack>
            <Button sx={{ width: 196 }} onClick={() => navigate("/dashboard")}>
              Return to Dashboard
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
