import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import React, { useState } from "react";
import { themeColors } from "@/app/theme";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

export default function ChatWithUsBtn() {
  const [isExpanded, setIsExpanded] = useState(false);

  // This variable prevents the shrinking button from remaining its in hover state until the next mouse movement.
  const [isHoverEnabled, setIsHoverEnabled] = useState(true);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const handleCloseClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleClick(event);
    // Briefly disable the hover state to prevent button from being stuck in hover state until next mouse movement.
    setIsHoverEnabled(false);
    setTimeout(() => {
      setIsHoverEnabled(true);
    }, 500);
  };

  return (
    <Button
      onClick={handleClick}
      sx={{
        "pointerEvents": isHoverEnabled && !isExpanded ? "auto" : "none",
        "width": isExpanded ? 260 : 140,
        "height": isExpanded ? 213 : 38,
        "borderRadius": isExpanded ? "6px" : "50px",
        "boxShadow": 2,
        "pt": isExpanded ? 2 : null,
        "px": isExpanded ? 3 : null,
        "backgroundColor": isExpanded ? themeColors.black.secondary : themeColors.success.secondary,
        "transition": "all 0.5s ease",
        "&:hover": {
          backgroundColor: isExpanded ? themeColors.black.secondary : null,
        },
      }}
    >
      <Stack direction={"row"} position={"fixed"} left={40} bottom={22}>
        <TextsmsOutlinedIcon
          sx={{
            mr: 1,
            color: isExpanded ? themeColors.white.primary : themeColors.black.primary,
          }}
        />
        <Typography color={isExpanded ? themeColors.white.primary : themeColors.black.primary} fontSize={14}>
          Support
        </Typography>
      </Stack>
      {isExpanded && (
        <Box position={"relative"} width={260} height={213}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            sx={{ mt: "7px", mb: "24px" }}
          >
            <Typography fontSize={12} color={themeColors.white.primary}>
              Beta version
            </Typography>
            <IconButton onClick={handleCloseClick} sx={{ height: 24, width: 24, pointerEvents: "all" }}>
              <ClearIcon sx={{ height: 16, width: 16, color: themeColors.white.primary }} />
            </IconButton>
          </Stack>
          <Typography color={themeColors.white.primary} fontSize={14} textAlign={"start"} position={"fixed"}>
            In Progress: <br />
            We're Working on This Feature. <br />
            Check Back Soon!
          </Typography>
        </Box>
      )}
    </Button>
  );
}
