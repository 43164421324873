import { themeColors } from "@/app/theme";
import { selectAuthUser } from "@/features/auth/authSlice";
import { Instrument, useGetInstrumentsQuery } from "@/features/instruments/instrumentsApiSlice";
import { CreateRfqPayload, useCreateRfqMutation } from "@/features/notifications/rfqApiSlice";
import { useAppSelector } from "@/store/hooks";
import ClearIcon from "@mui/icons-material/Clear";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useGetRiskDeskClientsQuery } from "../clientsApiSlice";
import { ParentOrder } from "../parentApiSlice";
import RfqSentIcon from "/rfqsent.svg";

interface RiskDeskClient {
  id: string;
  name: string;
}
// Prevents clicking on dialog box from invoking onClick property of its parent component.
const handleDialogClick = (event: React.MouseEvent) => {
  event.stopPropagation();
};

// TODO: Unused until expiration functionality is re-enabled.
// Use styled utility from MUI to prevent code duplication.
const StyledRadio = styled(Radio)({
  "&.Mui-checked": { color: themeColors.active.primary },
});

export interface SendRfqDialogProps {
  parent: ParentOrder;
  open: boolean;
  onClose: (
    event: React.SyntheticEvent<any>,
    reason: "backdropClick" | "escapeKeyDown" | "cancelButtonClick" | "sendButtonClick"
  ) => void;
}

export default function SendRfqDialog(props: SendRfqDialogProps) {
  const [riskDeskClients, setRiskDeskClients] = useState<RiskDeskClient[]>([]);
  const [riskDeskClientNames, setRiskDeskClientNames] = useState<string[]>([]);
  const { currentData } = useGetRiskDeskClientsQuery();
  const handleCloseButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.onClose(event, "cancelButtonClick");
  };

  // TODO: PLACEHOLDER HARDCODED BROKERS
  const authUser = useAppSelector(selectAuthUser);
  // const clientFilter = (client: { id: string; name: string }): boolean => {
  //   if (authUser?.["client-id"] === client.id) {
  //     // Can't send an RFQ to yourself.
  //     return false;
  //   } else {
  //     if (authUser?.username.includes("user")) {
  //       // I.e. user-n test accounts can send to everyone.
  //       return true;
  //     } else {
  //       // But the MVP test users can only see eachother.
  //       return client.name.includes("Bank");
  //     }
  //   }
  // };
  // const allBrokers = clients.filter(clientFilter)?.map((c) => c.name) || [];

  // Tracking switch variable so that we can control colour of associated labels
  const [showDirection, setShowDirection] = useState(true);
  // Tracking selected brokers
  const [selectedBrokers, setSelectedBrokers] = useState<string[]>([]);
  const [allSelected, setAllSelected] = useState(true);

  // We need to know the instrument's minimum block size.
  const [instrument, setInstrument] = useState<Instrument | null>(null);

  const { data: instrumentsData, isLoading: isLoadingInstruments } = useGetInstrumentsQuery();

  useEffect(() => {
    if (currentData?.length) {
      const clients = currentData.map((c) => ({ id: c["client-id"], name: c["client-name"] }));
      setRiskDeskClients(clients);
      const clientNames = clients.map((c) => c.name);
      setRiskDeskClientNames(clientNames);
      setSelectedBrokers(clientNames);
    } else {
      setRiskDeskClients([]);
      setRiskDeskClientNames([]);
      setSelectedBrokers([]);
    }
  }, [currentData]);

  useEffect(() => {
    if (instrumentsData) {
      setInstrument(instrumentsData.find((i) => i["underlying-name"] === props.parent.underlying) || null);
    }
  }, [instrumentsData]);

  const handleBrokerChange = (event: SelectChangeEvent<typeof selectedBrokers>) => {
    const {
      target: { value },
    } = event;
    const newValue = typeof value === "string" ? value.split(", ") : value;

    if (newValue.includes("All")) {
      if (allSelected) {
        setSelectedBrokers([]);
        setAllSelected(false);
      } else {
        setSelectedBrokers(riskDeskClientNames);
        setAllSelected(true);
      }
    } else {
      setSelectedBrokers(newValue);
      setAllSelected(newValue.length === riskDeskClientNames.length);
    }
  };

  const isBrokerSelected = (broker: string) => selectedBrokers.includes(broker);

  const remainingTotal = props.parent["parent-order-aggregation"]?.["remaining-total"];

  const [quantity, setQuantity] = useState(remainingTotal !== undefined ? remainingTotal : props.parent.quantity);

  // TODO: Unused request states
  const [
    sendRfq,
    { error: sendError, isLoading: isLoadingSend, isSuccess: isSuccessSend, isError: isErrorSubmit, reset: resetSend },
  ] = useCreateRfqMutation();

  // This hook ensures that resetting the send state variables is not cancelled out by the closing of the dialog.
  useEffect(() => {
    // The reset call is wrapped in a time-out to prevent the dialog from flickering back to its non sent state
    // for a brief moment before closing.
    setTimeout(() => {
      resetSend();
      setQuantity(remainingTotal !== undefined ? remainingTotal : props.parent.quantity);
    }, 100);
  }, [props.open]);

  const handleSendButton = async (event: React.MouseEvent<HTMLButtonElement>) => {
    const payload: CreateRfqPayload = {
      "parent-id": props.parent["parent-id"],
      "direction": showDirection,
      "quantity": quantity,
      // TODO: Placeholder sending broker
      "broker": authUser?.["default-broker"] || "",
      // TODO: Hardcoded recipients - the API validates that a legitimate client ID is being used.
      "recipients": riskDeskClients.filter((c) => selectedBrokers.includes(c.name)).map((c) => c.id),
      "duration": "P1D",
    };

    try {
      await sendRfq(payload);
      // RFQ Sent screen will render for 2 seconds before dialog closes
      setTimeout(() => {
        props.onClose(event, "sendButtonClick");
      }, 2000);
    } catch (error) {
      console.log("[ SendRfqDialog ] handleSendButton ERROR: ", error);
    }
  };

  const isErrorQuantity =
    !quantity ||
    (remainingTotal !== undefined && quantity > remainingTotal) ||
    (!!instrument && quantity < instrument["min-quantity"]);

  const isButtonDisabled = isErrorQuantity || selectedBrokers.length === 0 || isLoadingInstruments;
  if (!isSuccessSend) {
    return (
      <Dialog
        open={props.open}
        onClose={props.onClose}
        onClick={handleDialogClick}
        PaperProps={{ style: { width: "550px", height: "360px" } }}
      >
        <DialogTitle id={"dialog-title"}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Stack>
              <Typography fontSize={21} color={themeColors.black.secondary}>
                Create RFQ
              </Typography>
              <Typography fontSize={12} color={themeColors.black.secondary}>
                Please edit the fields below if they do not match your needs.
              </Typography>
            </Stack>
            <IconButton
              onClick={handleCloseButton}
              sx={{
                height: 28,
                width: 28,
                color: themeColors.text.primary,
              }}
            >
              <ClearIcon
                sx={{
                  height: 20,
                  width: 20,
                  color: themeColors.text.primary,
                }}
              />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container rowSpacing={5} columnSpacing={2}>
            <Grid item xs={6}>
              <FormControl>
                <FormLabel sx={{ fontSize: 11, color: themeColors.black.secondary }}>Show direction</FormLabel>
                <Stack direction={"row"}>
                  <FormLabel
                    sx={{
                      fontSize: 14,
                      mt: 1,
                      color: !showDirection ? themeColors.black.primary : themeColors.text.disabled,
                    }}
                  >
                    No
                  </FormLabel>
                  <Switch checked={showDirection} onClick={() => setShowDirection(!showDirection)} color={"info"} />
                  <FormLabel
                    sx={{
                      fontSize: 14,
                      mt: 1,
                      color: showDirection ? themeColors.black.primary : themeColors.text.disabled,
                    }}
                  >
                    Yes
                  </FormLabel>
                </Stack>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormLabel sx={{ fontSize: 11, color: themeColors.black.secondary }}>Time limit</FormLabel>
              <Typography fontSize={14} color={themeColors.black.primary}>
                One day (default)
              </Typography>
            </Grid>
            {/*<Grid item xs={6}>*/}
            {/*  <FormControl>*/}
            {/*    <FormLabel sx={{ fontSize: 11 }}>*/}
            {/*      Select time limit (minutes)*/}
            {/*    </FormLabel>*/}
            {/*    <RadioGroup row defaultValue={"3"}>*/}
            {/*      <FormControlLabel*/}
            {/*        control={<StyledRadio size="small" />}*/}
            {/*        label={<Typography sx={{ fontSize: 14 }}>1</Typography>}*/}
            {/*        value={"1"}*/}
            {/*      />*/}
            {/*      <FormControlLabel*/}
            {/*        control={<StyledRadio size="small" />}*/}
            {/*        label={<Typography sx={{ fontSize: 14 }}>2</Typography>}*/}
            {/*        value={"2"}*/}
            {/*      />*/}
            {/*      <FormControlLabel*/}
            {/*        control={<StyledRadio size="small" />}*/}
            {/*        label={<Typography sx={{ fontSize: 14 }}>3</Typography>}*/}
            {/*        value={"3"}*/}
            {/*      />*/}
            {/*      <FormControlLabel*/}
            {/*        control={<StyledRadio size="small" />}*/}
            {/*        label={<Typography sx={{ fontSize: 14 }}>4</Typography>}*/}
            {/*        value={"4"}*/}
            {/*      />*/}
            {/*      <FormControlLabel*/}
            {/*        control={<StyledRadio size="small" />}*/}
            {/*        label={<Typography sx={{ fontSize: 14 }}>5</Typography>}*/}
            {/*        value={"5"}*/}
            {/*      />*/}
            {/*    </RadioGroup>*/}
            {/*  </FormControl>*/}
            {/*</Grid>*/}
            <Grid item xs={6}>
              <FormControl>
                <InputLabel
                  sx={{
                    "&.Mui-focused": { color: themeColors.active.primary },
                  }}
                >
                  Broker
                </InputLabel>
                <Select
                  label={"Broker"}
                  size={"small"}
                  multiple
                  value={selectedBrokers}
                  onChange={handleBrokerChange}
                  renderValue={(selected) => (allSelected ? "All" : selected.join(", "))}
                  sx={{
                    "width": 210,
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: themeColors.active.primary,
                    },
                  }}
                >
                  <MenuItem key={"All"} value={"All"}>
                    <Checkbox
                      color={"info"}
                      checked={allSelected}
                      indeterminate={!allSelected && riskDeskClientNames.some((name) => isBrokerSelected(name))}
                    />
                    <ListItemText primary={"All"} />
                  </MenuItem>
                  {riskDeskClientNames.map(
                    (name) =>
                      name && (
                        <MenuItem key={name} value={name}>
                          <Checkbox color={"info"} checked={selectedBrokers.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      )
                  )}
                </Select>
                <FormLabel sx={{ fontSize: 12, mt: 1 }}>Choose brokers individually</FormLabel>
              </FormControl>
            </Grid>
            <Grid item xs={6} display="flex" flexDirection="column">
              <NumericFormat
                value={quantity}
                displayType={"input"}
                thousandSeparator
                customInput={TextField}
                label={"Quantity"}
                size={"small"}
                onValueChange={(values) => {
                  setQuantity(values?.floatValue ?? 0);
                }}
                error={isErrorQuantity}
                InputLabelProps={{ shrink: true }}
                sx={{
                  "mr": 6,
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: themeColors.active.primary,
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: themeColors.active.primary,
                  },
                }}
              />
              <FormLabel sx={{ fontSize: 12, mt: 1 }}>Adjust amount as necessary</FormLabel>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ py: 2, px: 3 }}>
          <LoadingButton
            color={"success"}
            variant={"contained"}
            size={"large"}
            onClick={handleSendButton}
            loading={isLoadingSend}
            disabled={isButtonDisabled}
            sx={{ mb: 1, fontSize: 18, borderRadius: 2 }}
          >
            <span>Send</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  } else {
    return (
      <Dialog
        open={props.open}
        onClose={props.onClose}
        onClick={handleDialogClick}
        PaperProps={{ style: { width: "550px", height: "360px" } }}
      >
        <DialogTitle id={"dialog-title"}>
          <Box display={"flex"} justifyContent={"center"}>
            <Typography fontSize={21} color={themeColors.black.secondary}>
              RFQ Sent
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display={"flex"} justifyContent={"center"} mt={2}>
            <img src={RfqSentIcon} style={{ width: "88px", height: "88px" }} alt="rfq sent logo" />
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
}
