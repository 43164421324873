import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import AppBar from "@/features/dashboard/components/AppBar";
import StickyFooter from "@/components/StickyFooter";
import Box from "@mui/material/Box";
import NotFoundIcon from "/notfound.svg";
import Stack from "@mui/material/Stack";

/**
 * This component will be displayed whenever the app is requested to render
 * an undefined route.
 */
export default function PageNotFound() {
  return (
    <Container>
      <AppBar dashboard={false} />
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} mt={20}>
        <Stack alignItems={"center"} spacing={4}>
          <img src={NotFoundIcon} style={{ width: "88px", height: "88px" }} alt="warning logo" />
          <Stack alignItems={"center"}>
            <Typography variant={"h4"} fontWeight={"bold"} mb={1}>
              Oops! Page Not Found
            </Typography>
            <Typography>Looks like you've hit a dead-end. But don't worry,</Typography>
            <Typography>we're here to guide you back on track.</Typography>
          </Stack>
        </Stack>
      </Box>
      <StickyFooter />
    </Container>
  );
}
