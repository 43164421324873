import ErrorCard from "@/components/ErrorCard";
import ScrollBox from "@/components/ScrollBox";
import PriceCard from "@/features/prices/components/PriceCard";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { range } from "lodash";
import { useEffect, useState } from "react";
import { selectInstrument } from "../instruments/instrumentSlice";
import { Offer, OfferType } from "../offers/offersApiSlice";
import { OrderState } from "../orders/child/childApiSlice";
import { selectParent } from "../orders/parent/parentSlice";
import { getPrices, Price } from "./getPrices";
import mockedLivePrice from "./mockedLivePrice";
import {
  selectElectronicPrices,
  selectPrice,
  setIsEmpty,
  setPrice,
  setElectronicPrices as setStoreElectronicPrices,
} from "./pricesSlice";
import usePriceQueries from "./usePriceQueries";

export const Prices = () => {
  const selectedParent = useAppSelector(selectParent);
  const selectedPrice = useAppSelector(selectPrice);
  const selectedInstrument = useAppSelector(selectInstrument);
  const dispatch = useAppDispatch();

  const [activePrice, setActivePrice] = useState<number | null>(null);

  const {
    inflightOffers,
    inflightOffersError,
    isErrorOffersIds,
    isLoadingOfferIds,
    isSuccessOfferIds,
    refetchOfferIds,
    offerData,
    offersError,
    isErrorOffers,
    isLoadingOffers,
    isSuccessOffers,
    refetchOffers,
    inflightChildrenError,
    isErrorInflightChildren,
    isSuccessInflightChildren,
    refetchInflightChildren,
    childOrdersData,
    childOrdersError,
    isErrorChildOrders,
    isSuccessChildOrders,
    refetchChildOrders,
    getBestPrice,
  } = usePriceQueries();

  // Inform the application if there are no inflight offers.
  useEffect(() => {
    dispatch(setIsEmpty(!!!inflightOffers?.inflight?.length));
  }, [inflightOffers]);

  // Generate prices based on either the offers + instrument stats,
  // working orders, or a manually set limit price.
  const [prices, setPrices] = useState<Price[]>([]);
  const selectedElectronicPrices = useAppSelector(selectElectronicPrices);
  const [electronicPrices, setElectronicPrices] = useState(selectedElectronicPrices);
  useEffect(() => {
    // Timeout is used to debounce the function. User can freely enter a limit
    // price via the SummaryBox, and we need to ensure that the function does
    // not execute between keystrokes.
    const generatePrices = setTimeout(() => {
      const priceInfo = getPrices(inflightOffers?.stats, offerData, childOrdersData, selectedPrice, selectedInstrument);
      setPrices(priceInfo.prices);
      setElectronicPrices(mockedLivePrice(selectedInstrument));

      if (selectedPrice !== activePrice) {
        // Ensures the price cards know the current active price.
        setActivePrice(selectedPrice);
      }
    }, 100);

    return () => clearTimeout(generatePrices);
  }, [inflightOffers, childOrdersData, selectedPrice, offerData]);

  useEffect(() => {
    dispatch(setStoreElectronicPrices(electronicPrices));
  }, [electronicPrices]);

  const onClickPrice = (value: number) => {
    const newPrice = activePrice === value ? null : value;
    dispatch(setPrice(newPrice)); // Inform the SummaryBox.
    setActivePrice(newPrice); // Inform PriceCard & OfferCard components.
  };

  if (isErrorOffersIds) {
    return <ErrorCard error={inflightOffersError} refreshFn={refetchOfferIds} />;
  }

  if (isErrorOffers) {
    return <ErrorCard error={offersError} refreshFn={refetchOffers} />;
  }

  if (isErrorInflightChildren) {
    return <ErrorCard error={inflightChildrenError} refreshFn={refetchInflightChildren} />;
  }

  if (isErrorChildOrders) {
    return <ErrorCard error={childOrdersError} refreshFn={refetchChildOrders} />;
  }

  if (isLoadingOfferIds || isLoadingOffers) {
    return (
      <Stack spacing={2}>
        {range(5).map((i) => (
          <Skeleton key={i} height={120} variant="rounded" width="100%">
            <PriceCard
              price={{ value: 0, isElectronic: false }}
              offers={[]}
              isBest={false}
              onClick={onClickPrice}
              selectedPrice={activePrice}
            />
          </Skeleton>
        ))}
      </Stack>
    );
  }

  if (isSuccessOfferIds && isSuccessOffers && prices.length) {
    // Find the best bid and ask (if they exist).
    const bestAsk = getBestPrice(OfferType.ASK, offerData || [], childOrdersData || []);

    const bestBid = getBestPrice(OfferType.BID, offerData || [], childOrdersData || []);

    return (
      <ScrollBox offset={!!selectedParent ? 4 : 12}>
        {prices.map((price, i) => {
          // Find all the offers at this price.
          const asksAtPrice =
            offerData?.filter((offer) => offer.price === price.value && offer["bid-ask"] === OfferType.ASK) || [];

          const bidsAtPrice =
            offerData?.filter((offer) => offer.price === price.value && offer["bid-ask"] === OfferType.BID) || [];

          // Include any working child orders.
          if (isSuccessInflightChildren && isSuccessChildOrders) {
            (
              childOrdersData?.filter((child) => child.price === price.value && child.state === OrderState.Working) ||
              []
            ).forEach((child) => {
              if (child["bid-ask"] === OfferType.ASK) {
                asksAtPrice.push(child as Offer);
              } else {
                bidsAtPrice.push(child as Offer);
              }
            });
          }

          // I.e. this price contains either the best ask or best bid.
          const isBestAsk = asksAtPrice.some((offer) => offer.price === bestAsk);
          const isBestBid = bidsAtPrice.some((offer) => offer.price === bestBid);

          return (
            <div key={i}>
              {!!asksAtPrice.length && (
                <PriceCard
                  price={price}
                  isFirst={i === 0}
                  isLast={i === prices.length - 1}
                  isBest={isBestAsk}
                  offers={asksAtPrice}
                  onClick={onClickPrice}
                  selectedPrice={activePrice}
                />
              )}
              {!!bidsAtPrice.length && (
                <PriceCard
                  data-testid={`price-card-${price}`}
                  price={price}
                  isFirst={i === 0}
                  isLast={i === prices.length - 1}
                  isBest={isBestBid}
                  offers={bidsAtPrice}
                  onClick={onClickPrice}
                  selectedPrice={activePrice}
                />
              )}
              {bidsAtPrice.length === 0 && asksAtPrice.length === 0 && (
                <PriceCard
                  price={price}
                  isFirst={i === 0}
                  isLast={i === prices.length - 1}
                  isBest={false}
                  offers={[]}
                  onClick={onClickPrice}
                  selectedPrice={activePrice}
                />
              )}
            </div>
          );
        })}
      </ScrollBox>
    );
  }

  return null;
};
