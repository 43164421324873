import { baseApi } from "@/config/BaseQuery";

export interface EnvResponse {
  "rolling-term-end": string;
  "rolling-term-start": string;
}

const environmentApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getEnvironment: build.query<EnvResponse, void>({
      query: () => "/env",
      providesTags: ["Env"],
    }),
  }),
});

export const { useGetEnvironmentQuery } = environmentApiSlice;
