import { themeColors } from "@/app/theme";
import LoginFooter from "@/components/LoginFooter";
import AppBar from "@/features/dashboard/components/AppBar";
import { useAppDispatch } from "@/store/hooks";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import {
  fetchUser,
  handleLogin,
  selectAuthErrorCode,
  selectAuthStatus,
  selectAuthToken,
  selectAuthUser,
} from "../auth/authSlice";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // The TextFields default to being empty, their error status depends on at least one textfield being empty due to
  // text being erased.
  const isDefaultUsername = useRef(true);
  const isDefaultPassword = useRef(true);

  const [isChecked, setIsChecked] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const location = useLocation();
  const redirect: string = location.state?.from?.pathname || undefined;

  const authToken = useSelector(selectAuthToken);
  const authStatus = useSelector(selectAuthStatus);
  const authUser = useSelector(selectAuthUser);

  const dispatch = useAppDispatch();

  const errorStatusCode = useSelector(selectAuthErrorCode);
  const errorMessage =
    errorStatusCode === 401
      ? "Your session has expired, please login again."
      : authStatus === "failed"
      ? "Invalid username or password, please try again."
      : "";

  useEffect(() => {
    if (authToken && !authUser && authStatus === "idle") {
      // Once the token has been received, fetch the user.
      dispatch(fetchUser(authToken));
    }
  }, [authToken, authUser, authStatus]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement> | React.KeyboardEvent) => {
    event.preventDefault();
    dispatch(
      handleLogin({
        username: username,
        password: password,
        isPersistent: isChecked,
      })
    );
  };

  const handleEnterPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && username.length !== 0 && password.length !== 0) {
      handleSubmit(event);
    }
  };

  if (authToken && authUser && authStatus === "idle") {
    // This will redirect the user if they've succesfully logged in and their
    // user info has been succesfully fetched from the API.

    // It could also act as a guard against people manually enertering the login
    // route when they are already authenticated, but this currently will not work
    // because the store is not persisted on refresh.
    // I.e. authUser will always be null if the page is refreshed.
    return <Navigate to={redirect ? (redirect !== "/login" ? redirect : "/dashboard") : "/dashboard"} />;
  }

  const isErrorUsername = !isDefaultUsername.current && username.length === 0;
  const isErrorPassword = !isDefaultPassword.current && password.length === 0;

  return (
    <Container component="main" maxWidth="xs" onKeyUp={handleEnterPress}>
      <AppBar dashboard={false} />
      <Box
        sx={{
          pt: 8,
          mt: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Stack alignItems={"center"} spacing={3}>
          <Stack alignItems={"center"} spacing={1}>
            <Typography component="h1" variant="h4" fontWeight={"bold"}>
              Login
            </Typography>
            <Typography fontWeight={500} fontSize={18}>
              Sign in to your account to get started.
            </Typography>
          </Stack>
          <TextField
            margin="normal"
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setUsername(event.target.value);
              isDefaultUsername.current = false;
            }}
            // TextField has error status only when it is empty due to text being erased, the field defaults to having
            // an empty value which should not cause error status.
            error={isErrorUsername}
            InputLabelProps={{ shrink: true }}
            size={"small"}
            sx={{
              "width": 330,
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: isErrorUsername ? themeColors.error.primary : themeColors.active.primary,
                },
              },
            }}
          />
          <TextField
            margin="normal"
            name="password"
            label="Password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPassword(event.target.value);
              isDefaultPassword.current = false;
            }}
            error={isErrorPassword}
            type={showPassword ? "text" : "password"}
            InputLabelProps={{ shrink: true }}
            size={"small"}
            helperText={!!errorMessage ? errorMessage : null}
            FormHelperTextProps={{
              sx: { color: themeColors.error.primary, mt: 1 },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    color="secondary"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              "width": 330,
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: isErrorPassword ? themeColors.error.primary : themeColors.active.primary,
                },
              },
            }}
          />
        </Stack>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} width={330}>
            <FormControlLabel
              sx={{ alignSelf: "flex-start" }}
              control={
                <Checkbox
                  value="remember"
                  color="info"
                  checked={isChecked}
                  size={"small"}
                  onChange={() => setIsChecked(!isChecked)}
                />
              }
              label="Remember me"
            />
            <LoadingButton
              loading={authStatus === "loading"}
              disabled={username.length === 0 || password.length === 0}
              type="submit"
              size={"small"}
              variant="contained"
              color={"rfqDark"}
              sx={{ width: 120, mt: 3, mb: 2, ml: 14, alignSelf: "flex-end" }}
            >
              {/* Span protects against a bug involving google translate */}
              <span>Sign In</span>
            </LoadingButton>
          </Box>
        </Box>
      </Box>
      <LoginFooter />
    </Container>
  );
}
