import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useAcceptMatchMutation, useRejectMatchMutation } from "@/features/orders/match/matchApiSlice";
import { MatchRequest } from "../matchRequestApiSlice";
import { useState } from "react";
import { Rfq, useDismissRfqMutation } from "../rfqApiSlice";

export default function useNotificationMutations() {
  const [
    rejectMatch,
    { error: rejectError, isLoading: isLoadingReject, isSuccess: isSuccessReject, isError: isErrorReject },
  ] = useRejectMatchMutation();

  const handleRejectMatch = async (n: MatchRequest) => {
    try {
      const response = await rejectMatch(n["match-request-id"]).unwrap();
    } catch (error) {
      console.log("[ useNotificationMutations ] handleRejectMatch error: ", error);
      if (typeof error === "object" && !!error && "data" in error) {
        handleNotificationError(error as FetchBaseQueryError);
      }
    }
  };

  const [
    acceptMatch,
    { error: acceptError, isLoading: isLoadingAccept, isSuccess: isSuccessAccept, isError: isErrorAccept },
  ] = useAcceptMatchMutation();

  const handleAcceptMatch = async (n: MatchRequest) => {
    try {
      const response = await acceptMatch(n["match-request-id"]).unwrap();
    } catch (error) {
      console.log("[ useNotificationMutations ] handleAcceptMatch error: ", error);
      if (typeof error === "object" && !!error && "data" in error) {
        handleNotificationError(error as FetchBaseQueryError);
      }
    }
  };

  const [
    dismissRfq,
    { error: dismissError, isLoading: isLoadingDismiss, isSuccess: isSuccessDismiss, isError: isErrorDismiss },
  ] = useDismissRfqMutation();

  const handleDismissRfq = async (n: Rfq) => {
    try {
      const response = await dismissRfq(n["rfq-request-id"]).unwrap();
    } catch (error) {
      console.log("[ useNotificationMutations ] handleDissmissRfq error: ", error);
      if (typeof error === "object" && !!error && "data" in error) {
        handleNotificationError(error as FetchBaseQueryError);
      }
    }
  };

  const [error, setError] = useState("");
  const handleNotificationError = (error: FetchBaseQueryError) =>
    setError(`[${error.status}] ${error.data ? JSON.stringify(error.data) : "An unknown error occured."}`);

  return {
    handleRejectMatch,
    isLoadingReject,
    handleAcceptMatch,
    isLoadingAccept,
    handleDismissRfq,
    isLoadingDismiss,
    error,
  };
}
