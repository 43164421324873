import { baseApi } from "@/config/BaseQuery";
import { ChildOrder } from "../child/childApiSlice";

export interface MatchRequestPayload {
  "client-id": string;
  "child-id": string;
  "requesting-parent-id": string;
  "quantity": number;
}

export interface MatchRequestResponse {
  "actual-quantity": number;
  "child-id": string;
  "offering-parent-id": string;
  "order-match-request-id": string;
  "requesting-parent-id": string;
  "resolution": string;
}

export interface MatchAcceptResponse {
  "state": string;
  "child-id": string;
  "update-match-requests": [
    {
      "order-match-request-id": string;
      "child-id": string;
      "offering-parent-id": string;
      "requesting-parent-id": string;
      "actual-quantity": number;
      "resolution": string;
    }
  ];
}

const matchApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    acceptMatch: build.mutation<MatchAcceptResponse, string>({
      query: (id) => ({
        url: `/orders/match/accept/${id}`,
        method: "PUT",
      }),
      invalidatesTags: [
        "InflightChildOrders",
        "SelectedChildOrder",
        "MatchRequestNotifications",
        "SelectedParentOrder",
      ],
    }),
    rejectMatch: build.mutation<ChildOrder, string>({
      query: (id) => ({
        url: `/orders/match/reject/${id}`,
        method: "PUT",
      }),
      invalidatesTags: [
        "InflightChildOrders",
        "SelectedChildOrder",
        "MatchRequestNotifications",
        "SelectedParentOrder",
      ],
    }),
    cancelMatch: build.mutation<void, string>({
      query: (id) => ({
        url: `/orders/match/cancel/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SelectedChildOrder", "InflightChildOrders", "Offers", "SelectedParentOrder"],
    }),
    requestMatch: build.mutation<MatchRequestResponse, MatchRequestPayload>({
      query: (body) => ({
        url: "/orders/match/request",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["SelectedChildOrder", "InflightChildOrders", "Offers", "SelectedParentOrder"],
    }),
  }),
});

export const { useAcceptMatchMutation, useRejectMatchMutation, useCancelMatchMutation, useRequestMatchMutation } =
  matchApiSlice;
