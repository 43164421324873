import CookieConsent from "@/components/CookieConsent";
import ChatWithUsBtn from "@/components/buttons/ChatWithUsBtn";
import ChildOrders from "@/features/orders/child/ChildOrders";
import { selectParent } from "@/features/orders/parent/parentSlice";
import { Prices } from "@/features/prices/Prices";
import SummaryBox from "@/features/summary/SummaryBox";
import { useAppSelector } from "@/store/hooks";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { selectActiveRfq } from "../notifications/notificationSlice";
import ParentOrders from "../orders/parent/ParentOrders";
import AppBar from "./components/AppBar";
import UnderlyingSelect from "./components/UnderlyingSelect";

export default function Dashboard() {
  const selectedParent = useAppSelector(selectParent);
  const activeRfq = useAppSelector(selectActiveRfq);

  return (
    <>
      <CookieConsent />
      <AppBar dashboard />
      <Grid component="main" container spacing={2} sx={{ mt: 14 }}>
        <Grid item xs={4}>
          <Container maxWidth={false} disableGutters>
            <ParentOrders />
            <Stack spacing={1.5} sx={{ position: "fixed", bottom: 16, left: 16 }}>
              {/*GUIDANCE AND PRICING CHART BUTTONS DISABLED FOR MVP*/}
              {/* <GuidanceBtn /> */}
              {/* <PricingChartBtn /> */}
              <ChatWithUsBtn />
            </Stack>
          </Container>
        </Grid>
        <Grid item xs={8}>
          <Container maxWidth={false} disableGutters>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <ChildOrders />
              </Grid>
              <Grid item xs={8}>
                {!selectedParent && !activeRfq && <UnderlyingSelect />}
                <Prices />
                <SummaryBox />
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </>
  );
}
