import { ChildOrder, OrderState, useDeleteChildOrderMutation } from "@/features/orders/child/childApiSlice";
import ClearIcon from "@mui/icons-material/Clear";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useCancelMatchMutation } from "../../match/matchApiSlice";

export interface CancelButtonProps {
  data: ChildOrder;
}

export default function CancelButton(props: CancelButtonProps) {
  const [isConfirming, setIsConfirming] = useState(false);

  const [deleteChildOrder, { error: deleteError, isLoading: isLoadingDelete, isError: isErrorDelete }] =
    useDeleteChildOrderMutation();

  const [cancelMatchRequest, { error: cancelError, isLoading: isLoadingCancel, isError: isErrorCancel }] =
    useCancelMatchMutation();

  const handleClickConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsConfirming(!isConfirming);
  };

  const handleClickCancelOrder = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    try {
      if (props.data.state === OrderState.Working) {
        await deleteChildOrder(props.data["child-id"]).unwrap();
      } else if (props.data.state === OrderState.AwaitingMatch && props.data["match-request-id"]) {
        await cancelMatchRequest(props.data["match-request-id"]).unwrap();
      }
    } catch (error) {
      console.log("[ CancelButton ] handleClickCancelOrder Error: ", error);
    }
  };

  return !isConfirming ? (
    <Button
      startIcon={<ClearIcon sx={{ height: 12, width: 12 }} />}
      color="error"
      sx={{ height: 20, fontSize: 10 }}
      onClick={handleClickConfirm}
    >
      Cancel this order
    </Button>
  ) : (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <Typography fontSize={10}>Are you sure?</Typography>
      <LoadingButton
        loading={isLoadingCancel || isLoadingDelete}
        sx={{ height: 20, minWidth: 24, fontSize: 10 }}
        color="error"
        onClick={handleClickCancelOrder}
      >
        Yes
      </LoadingButton>
      <Typography fontSize={10}>/</Typography>
      <Button
        disabled={isLoadingCancel || isLoadingDelete}
        sx={{ height: 20, minWidth: 24, fontSize: 10 }}
        onClick={handleClickConfirm}
      >
        No
      </Button>
    </Stack>
  );
}
