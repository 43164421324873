import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { themeColors } from "@/app/theme";
import { Link } from "react-router-dom";

/**
 * A full-width box placed at the bottom of the viewport.
 * Useful for displaying contact information, and for taming scroll bars.
 */
export default function StickyFooter() {
  return (
    <Stack
      component="footer"
      direction={"row"}
      spacing={10}
      justifyContent="center"
      sx={{
        width: "100vw",
        position: "absolute",
        left: 0,
        bottom: 0,
        backgrounColor: themeColors.white.quaternary,
        py: 2,
      }}
    >
      <Stack spacing={2}>
        <Typography fontWeight={"bold"}>What could have happened?</Typography>
        <Stack spacing={1}>
          <Typography fontSize={12}>
            <Link to={"/dashboard"} color={themeColors.active.primary}>
              Home
            </Link>{" "}
            head back there
          </Typography>
          <Typography fontSize={12}>
            <Link
              to={"/contact"} // TODO: Contact page.
              color={themeColors.active.primary}
            >
              Contact us
            </Link>{" "}
            if you need assistance
          </Typography>
        </Stack>
      </Stack>
      <Stack spacing={2}>
        <Typography fontWeight={"bold"}>What could have happened?</Typography>
        <Stack spacing={1}>
          <Typography fontSize={12}>The page might have moved or been deleted.</Typography>
          <Typography fontSize={12}>The URL could have been a typo.</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
