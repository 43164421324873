import type { PayloadAction } from "@reduxjs/toolkit";
import { createAppSlice } from "@/store/createAppSlice";
import { ParentOrder } from "./parentApiSlice";
import { resetState } from "@/store/reset";

export interface ParentSliceState {
  parent: ParentOrder | null;
}

const initialState: ParentSliceState = {
  parent: null,
};

export const parentSlice = createAppSlice({
  name: "parent",
  initialState,
  reducers: (create) => ({
    setParentOrder: create.reducer((state, action: PayloadAction<ParentOrder | null>) => {
      state.parent = action.payload;
    }),
  }),
  extraReducers: (builder) => {
    builder.addCase(resetState.type, (state) => {
      Object.assign(state, initialState);
    });
  },
  selectors: {
    selectParent: (state) => state.parent,
  },
});

export const { setParentOrder } = parentSlice.actions;

export const { selectParent } = parentSlice.selectors;
