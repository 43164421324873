/**
 * Timeouts, in ms, for use with the pollingInterval option for RTK-Query hooks.
 */
export const refetchTimeouts = {
  fast: 15000,
  medium: 30000,
  slow: 60000,
};

const isProduction = import.meta.env.PROD;

export const DEFAULT_BROKER = isProduction ? "Barclays" : "BronzeBank";
