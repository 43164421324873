import { createAppSlice } from "@/store/createAppSlice";
import { resetState } from "@/store/reset";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface ErrorState {
  status: number | null;
}

const initialState: ErrorState = {
  status: null,
};

export const errorSlice = createAppSlice({
  name: "error",
  initialState,
  reducers: (create) => ({
    handleError: create.reducer((state, action?: PayloadAction<ErrorState>) => {
      state.status = action?.payload?.status || null;
    }),
    handleSuccess: create.reducer((state) => {
      state.status = null;
    }),
  }),
  extraReducers: (builder) => {
    builder.addCase(resetState.type, (state) => {
      Object.assign(state, initialState);
    });
  },
  selectors: {
    selectErrorStatus: (state) => state.status,
  },
});

export const { handleError, handleSuccess } = errorSlice.actions;

export const { selectErrorStatus } = errorSlice.selectors;
