import { themeColors } from "@/app/theme";
import { getRfqDirection, selectActiveRfq } from "@/features/notifications/notificationSlice";
import useTimer from "@/hooks/useTimer";
import { useAppSelector } from "@/store/hooks";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { numericFormatter } from "react-number-format";

export default function RfqDetails() {
  const activeRfq = useAppSelector(selectActiveRfq);
  const { timer } = useTimer(activeRfq ? activeRfq["seconds-to-expiration"] : 0);

  if (!activeRfq) return null;

  return (
    <Stack
      sx={{
        backgroundColor: themeColors.black.primary,
        py: 3,
        px: 2,
        borderRadius: "8px 0 0 8px",
      }}
    >
      <Typography variant="h6" color="white">
        RFQ Request
      </Typography>

      <Stack direction="row" spacing={4}>
        <Stack>
          <Typography variant="body2" color="white">
            Total amount:
          </Typography>
          <Typography variant="body2" color="white">
            Client:
          </Typography>
          <Typography variant="body2" color="white">
            Direction:
          </Typography>
          <Typography variant="body2" color="white">
            Time to action:
          </Typography>
        </Stack>

        <Stack>
          <Typography variant="body2" color="white">
            {numericFormatter(activeRfq.quantity.toString(), {
              thousandSeparator: true,
            })}
          </Typography>
          <Typography variant="body2" color="white">
            {activeRfq["request-origin"]}
          </Typography>
          <Typography variant="body2" color="white">
            {getRfqDirection(activeRfq)}
          </Typography>
          <Typography
            variant="body2"
            color="white"
            // Fixed timer width stops the clock from jumping around.
            // If the string's length is 5 === hh:mm format.
            width={timer.length === 5 ? 48 : 64}
          >
            {timer}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
