import { useMenu } from "@/hooks/useMenu";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CircleIcon from "@mui/icons-material/Circle";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import dayjs from "@/utils/Time";
import Button from "@mui/material/Button";
import { useEffect, useRef, useState } from "react";
import NotificationsTab from "./NotificationsTab";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { isMatchRequest, isRfq, selectActiveNotification, setActiveNotification } from "../notificationSlice";
import NotificationDetails from "./NotificationDetails";
import { themeColors } from "@/app/theme";
import { useGetMatchRequestsQuery } from "../matchRequestApiSlice";
import { refetchTimeouts } from "@/utils/Constants";
import { useGetRfqNotificationsQuery } from "../rfqApiSlice";

export enum TabType {
  MATCH_REQUESTS = "Notifications",
  RFQS = "Requests",
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

/**
 * Handles hiding/ showing the tab contents when the tab index changes.
 */
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

/**
 * Handles adding accessibility props to the MUI Tabs.
 */
function a11yProps(index: number) {
  return {
    "id": `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export default function NotificationsMenu() {
  const { anchorEl, isMenuOpen, handleMenuOpen, handleMenuClose } = useMenu();
  const [tabIndex, setTabIndex] = useState(0);

  const dispatch = useAppDispatch();
  const activeNotification = useAppSelector(selectActiveNotification);
  const [activeNotificationData, setActiveNotificationData] = useState(activeNotification);

  const bellIconRef = useRef<HTMLButtonElement>(null);

  const {
    currentData: matchRequestData,
    error: matchRequestError,
    isError: isErrorMatchRequest,
    isLoading: isLoadingMatchRequest,
    isFetching: isFetchingMatchRequest,
    isSuccess: isSuccessMatchRequest,
    refetch: refetchMatchRequest,
  } = useGetMatchRequestsQuery(undefined, {
    // Regularly refetch match requests to passively check for updates.
    pollingInterval: refetchTimeouts.fast,
  });

  const {
    currentData: rfqData,
    error: rfqError,
    isError: isErrorRfq,
    isLoading: isLoadingRfq,
    isFetching: isFetchingRfq,
    isSuccess: isSuccessRfq,
    refetch: refetchRfq,
  } = useGetRfqNotificationsQuery(undefined, {
    // Regularly refetch RFQs to passively check for updates.
    pollingInterval: refetchTimeouts.fast,
  });

  useEffect(() => {
    // As the API sends the deadline as "seconds-to-expiry", we need to
    // regularly re-fetch the notifications whenever the UI updates.
    // E.g. when switching tabs, or navigating to/ from from the detail view.
    if (isMenuOpen) {
      refetchMatchRequest();
      refetchRfq();
    }
  }, [isMenuOpen, tabIndex, activeNotification]);

  useEffect(() => {
    // As a consequence of the above ^ we need to ensure that the data being
    // passed into the detail view is the most up-to-date available, in order
    // to esnure that the countdown timers are syncronised.
    if (activeNotification === null) {
      setActiveNotificationData(null);
    } else {
      if (isMatchRequest(activeNotification)) {
        setActiveNotificationData(
          matchRequestData?.find((n) => {
            return n["match-request-id"] === activeNotification["match-request-id"];
          }) || null
        );
      }
      if (isRfq(activeNotification)) {
        setActiveNotificationData(
          rfqData?.find((n) => {
            return n["rfq-request-id"] === activeNotification["rfq-request-id"];
          }) || null
        );
      }
    }
  }, [matchRequestData, rfqData, activeNotification]);

  // If the menu is closed, we need to automatically set the selected notification
  // to the most recent notification. This ensures that the notification details
  // will be presented by default.
  useEffect(() => {
    if (matchRequestData && rfqData) {
      const allData = [...(matchRequestData || []), ...(rfqData || [])];
      const mostRecent = allData.length
        ? allData.reduce(function (prev, current) {
            return prev && prev["created-at"] > current["created-at"] ? prev : current;
          })
        : undefined;
      if (!isMenuOpen && !!mostRecent) {
        // Make sure the most recent notification will appear automatically.
        dispatch(setActiveNotification(mostRecent));
        if (isMatchRequest(mostRecent)) {
          setTabIndex(0);
        }
        if (isRfq(mostRecent)) {
          setTabIndex(1);
        }
        // Open the menu if the most recent notification was created within the
        // refresh timeout of the API calls (15s).
        const now = Math.round(new Date().getTime() / 1000);
        if (now - mostRecent["created-at"] <= refetchTimeouts.fast / 1000 && !!bellIconRef.current) {
          bellIconRef.current.click();
        }
      }
    }
  }, [matchRequestData, rfqData, isMenuOpen]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const now = dayjs();

  const menuId = "notifications-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      slotProps={{
        paper: {
          style: {
            backgroundColor: themeColors.white.tertiary,
          },
        },
        root: {
          slotProps: {
            backdrop: {
              style: {
                backgroundColor: "rgba(255,255,255, 0.9)",
              },
            },
          },
        },
      }}
    >
      <Stack width={432}>
        {/* Title and Close Button */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mx: 2,
            mt: 1,
          }}
        >
          <Typography variant="h6">Notifications</Typography>
          <Tooltip title="Close" placement="top">
            <IconButton onClick={handleMenuClose}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Box>

        {/* Time and Date */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mx: 2,
          }}
        >
          <Typography variant="caption">{`${now.format("HH:mm")}`}</Typography>
          <Typography variant="caption">{`${now.format("DD/MM/YY")}`}</Typography>
        </Box>

        {/* div prevents button from stretching to full width of parent container */}
        <div>
          {activeNotificationData === null ? (
            <Button sx={{ ml: 1, color: themeColors.black.secondary, fontSize: 14 }} disabled>
              Mark all as read
            </Button>
          ) : (
            <Button
              sx={{ ml: 1, color: themeColors.black.secondary, fontSize: 14 }}
              startIcon={<ChevronLeftIcon />}
              onClick={() => dispatch(setActiveNotification(null))}
            >
              Back to list view
            </Button>
          )}
        </div>

        {activeNotificationData === null ? (
          // Notifications / Requests Tabs
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={tabIndex}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="notifcations tabs"
            >
              <Tab sx={{ ml: 2, textTransform: "none" }} label={TabType.MATCH_REQUESTS} {...a11yProps(0)} />
              <Tab sx={{ textTransform: "none" }} label={TabType.RFQS} {...a11yProps(1)} />
            </Tabs>
            <CustomTabPanel value={tabIndex} index={0}>
              <NotificationsTab
                notifications={matchRequestData}
                type={TabType.MATCH_REQUESTS}
                isFetching={isFetchingMatchRequest}
                refetch={refetchMatchRequest}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabIndex} index={1}>
              <NotificationsTab
                notifications={rfqData}
                type={TabType.RFQS}
                isFetching={isFetchingRfq}
                refetch={refetchRfq}
              />
            </CustomTabPanel>
          </Box>
        ) : (
          <NotificationDetails notification={activeNotificationData} handleClose={handleMenuClose} />
        )}
        {/* Bottom circle icon */}
        <Box display="flex" justifyContent="center">
          <CircleIcon color="disabled" sx={{ fontSize: 12 }} />
        </Box>
      </Stack>
    </Menu>
  );

  return (
    <>
      <IconButton
        size="large"
        color="primary"
        aria-label="notifications-bell"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleMenuOpen}
        ref={bellIconRef}
      >
        <Badge
          role="notifications-badge"
          variant="dot"
          overlap="circular"
          color="secondary"
          invisible={!matchRequestData?.length && !rfqData?.length}
        >
          <NotificationsOutlinedIcon sx={{ fontSize: 30 }} />
        </Badge>
      </IconButton>
      {renderMenu}
    </>
  );
}
