import { CH, DE, EU, FR, IT } from "country-flag-icons/react/3x2";

export interface FlagIconProps {
  underlying: string;
  height: number;
}

export default function FlagIcon(props: FlagIconProps) {
  if (!props.underlying) return null;
  switch (props.underlying) {
    case "Euro Stoxx 50":
    case "Euro Stoxx Banks":
    case "Stoxx Europe 600":
      return <EU height={props.height} />;
    case "Bund":
    case "Schatz":
    case "Buxl":
    case "Bobl":
      return <DE height={props.height} />;
    case "OAT":
      return <FR height={props.height} />;
    case "BTP":
    case "Short BTP":
      return <IT height={props.height} />;
    case "SMI":
      return <CH height={props.height} />;
    default:
      return null;
  }
}
