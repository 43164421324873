import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
import { themeColors } from "@/app/theme";
import ErrorCard from "@/components/ErrorCard";
import { OrderState, orderStateLabels, useGetChildOrderQuery } from "@/features/orders/child/childApiSlice";
import { refetchTimeouts } from "@/utils/Constants";
import { metricFormatter } from "@/utils/String";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { numericFormatter } from "react-number-format";
import { ParentOrder } from "../../parent/parentApiSlice";
import CancelButton from "./CancelButton";
import Logo from "/favicon.svg";

export interface ChildOrderCardProps {
  parent: ParentOrder;
  id: string;
  index: number;
  expanded: number | boolean;
  handleChange: (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => void;
}

export default function ChildOrderCard(props: ChildOrderCardProps) {
  const { data, error, isError, isLoading, isFetching, isSuccess, refetch } = useGetChildOrderQuery(props.id, {
    // Refetch child data every minute to check for status updates.
    pollingInterval: refetchTimeouts.fast,
  });

  if (isError) {
    return <ErrorCard error={error} refreshFn={refetch} />;
  }

  if (isLoading) {
    return <Skeleton height={104} width="100%" variant="rounded" />;
  }

  if (isSuccess) {
    return (
      <Accordion
        expanded={false}
        // expanded={props.expanded === props.index}
        // onChange={props.handleChange(props.index)}
        elevation={4}
        disableGutters
        square={true}
        sx={{
          "mt": props.index === 0 ? 0 : "-24px",
          "borderRadius": 3,
          "zIndex": props.index,
          "&:before": {
            display: "none",
          },
          "backgroundColor": themeColors.state[data.state],
          "color": data.state === OrderState.BrokerFilled ? themeColors.white.primary : themeColors.text.primary,
          "&:hover": {
            filter: "brightness(0.96)",
          },
        }}
      >
        <AccordionSummary aria-controls={`child-${props.index}-content`} id={`child-${props.index}-header`}>
          <Stack sx={{ flex: 1, height: 80 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Stack direction="row" spacing={1}>
                <Typography variant="h5" fontWeight={500}>
                  {metricFormatter(data.quantity, 1)}
                </Typography>
                <Typography variant="h5">at</Typography>
                <Typography variant="h5">
                  {numericFormatter(String(data.price), {
                    fixedDecimalScale: true,
                    decimalScale: 3,
                  })}
                </Typography>
              </Stack>

              <img style={{ height: 20 }} src={Logo} alt="InTick Logo" />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body1" fontWeight={500}>
                {orderStateLabels[data.state]}
              </Typography>

              {[OrderState.Working, OrderState.AwaitingMatch].includes(data.state) ? (
                <CancelButton data={data} />
              ) : null}
            </Box>
          </Stack>
        </AccordionSummary>
        {/* <AccordionDetails>
          <Stack sx={{ flex: 1 }}>
            <Box
              alignItems="center"
              justifyContent="space-between"
              display="flex"
            >
              <Typography fontSize={12}>Working on screen</Typography>
              <Typography fontSize={12}>Manual replace</Typography>
            </Box>
          </Stack>
        </AccordionDetails> */}
      </Accordion>
    );
  }

  return null;
}
