import { round, uniq } from "lodash";
import { InflightOfferStats, Offer } from "../offers/offersApiSlice";
import { ChildOrder, OrderState } from "../orders/child/childApiSlice";
import mockedLivePrice from "./mockedLivePrice";

export interface Price {
  value: number;
  isElectronic: boolean;
}

interface PriceInfo {
  prices: Price[];
  floor: number;
  ceiling: number;
}

/**
 * @returns The nearest electronic price above or below a given price.
 */
const getElectronicPrice = (type: "floor" | "ceiling", startPrice: number, tickSize: number) => {
  const electronicTickSize = round(tickSize * 10, 3);
  const divisor = electronicTickSize * 1000;
  const step = electronicTickSize * 100;
  // These multiplication factors we ensure that we are not dealing with any
  // decimals, and therefore won't run into any float division errors.
  let current = startPrice * 1000;
  let stepCount = 0;
  const maxStepCount = 10;

  if (type === "floor") {
    while (current % step !== 0) {
      // Round the current price down to the nearest InTick tick size.
      current -= 1;
    }
    while (current % divisor !== 0) {
      stepCount++;
      if (stepCount > maxStepCount) throw new Error("Max step count exceeded.");
      current -= step;
    }
    return current / 1000;
  } else {
    while (current % step !== 0) {
      // Round the current price up to the nearest InTick tick size.
      current += 1;
    }
    while (current % divisor !== 0) {
      stepCount++;
      if (stepCount > maxStepCount) throw new Error("Max step count exceeded.");
      current += step;
    }
    return current / 1000;
  }
};

/**
 * @returns An array of prices based on market offers, underlying tick size,
 * and any working orders.
 */
export const getPrices = (
  stats: InflightOfferStats | undefined,
  offers: Offer[] | undefined,
  childOrders: ChildOrder[] | undefined,
  selectedPrice: number | null,
  instrument: string | null
): PriceInfo => {
  console.log(`stats: ${JSON.stringify(stats)}`);
  console.log(`offers: ${JSON.stringify(offers)}`);
  console.log(`childOrders: ${JSON.stringify(childOrders)}`);
  console.log(`selectedPrice: ${selectedPrice}`);
  // If we get a bad response from the API, return an empty object.
  if (stats === undefined || offers === undefined || childOrders === undefined)
    return { prices: [], floor: 0, ceiling: 0 };

  const offerPrices = offers.map((offer) => offer.price);
  // Working orders are not included in the offers API response, so we need to
  // check to see if we have any (and what price they were created at).
  const workingOrderPrices =
    childOrders?.filter((order) => order.state === OrderState.Working)?.map((order) => order.price) || [];

  // Similarly, any child order that has progressed to "matched" or beyond will
  // not be returned in the offers response, but they still provide important
  // context for prices in the market.
  // --------------------------------- Removed at James G's request.
  // const matureChildPrices =
  //   childOrders
  //     ?.filter((order) => matureStates.includes(order.state))
  //     ?.map((order) => order.price) || [];

  const intickPrices: number[] = [];
  const electronicTickSize = round(stats.tick * 10, 3);

  const priceSources = [
    ...offerPrices,
    ...workingOrderPrices,
    // ...matureChildPrices,
  ];
  const includeLimitPriceAsMinSource =
    priceSources.length && selectedPrice !== null
      ? Math.abs(Math.min(...priceSources) - selectedPrice) < electronicTickSize
      : false;
  const min = priceSources.length
    ? Math.min(...priceSources, ...(includeLimitPriceAsMinSource && selectedPrice !== null ? [selectedPrice] : []))
    : selectedPrice || 0;

  const includeLimitPriceAsMaxSource =
    priceSources.length && selectedPrice !== null
      ? Math.abs(Math.min(...priceSources) - selectedPrice) < electronicTickSize
      : false;
  const max = priceSources.length
    ? Math.max(...priceSources, ...(includeLimitPriceAsMaxSource && selectedPrice !== null ? [selectedPrice] : []))
    : selectedPrice || 0;

  // E.g. for 0.025, precision === 3 (decimal places).
  const precision = Math.ceil(Math.abs(Math.log10(stats.tick)));

  //   const ceiling = round(getElectronicPrice("ceiling", max, stats.tick), precision);
  //   const floor = round(getElectronicPrice("floor", min, stats.tick), precision);
  const { ceiling, floor } = mockedLivePrice(instrument);

  const end = round(floor - stats.tick, precision);

  // A list of all the prices that should be included (but might not be exactly
  // an integer multiple of the InTick tick size).
  const importantPrices = uniq([min, max, ...workingOrderPrices, ...offerPrices]);

  if (selectedPrice !== null && !importantPrices.includes(selectedPrice)) {
    importantPrices.push(selectedPrice);
  }

  // Iterate from max -> min.
  for (let i = ceiling; round(i, precision) > end; i -= stats.tick) {
    // InTick intervals are added here (there should be 9 in total).
    // Need to account for JS float precision errors, hence lots of rounding.
    intickPrices.push(round(i, 3));
  }

  // Combine the InTick prices and the important prices, with no duplicates.
  const prices: Price[] = uniq([...importantPrices, ...intickPrices])
    .map((p) => {
      return {
        value: p,
        isElectronic: p === ceiling || p === floor,
      };
    })
    .sort((a, b) => b.value - a.value); // Return prices in descending order.

  return { prices, floor, ceiling };
};
