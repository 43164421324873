// 'Schatz', 0.005),

interface LivePrice {
  ceiling: number;
  floor: number;
}

const mockedLivePrice = (instrument: string | null): LivePrice => {
  if (instrument === "Euro Stoxx 50") {
    return {
      ceiling: 4981.46,
      floor: 4981.45,
    };
  }

  // TODO: Should handle 4 numbers after the decimal point.
  if (instrument === "Euro Stoxx Banks") {
    return {
      ceiling: 146.35,
      floor: 146.34,
    };
  }

  // TODO: Should handle 4 numbers after the decimal point.
  if (instrument === "Bobl") {
    return {
      ceiling: 110.59,
      floor: 110.58,
    };
  }

  // TODO: Should handle 4 numbers after the decimal point.
  if (instrument === "Stoxx Europe 600") {
    return {
      ceiling: 518.29,
      floor: 518.28,
    };
  }

  if (instrument === "Schatz") {
    return {
      ceiling: 110.59,
      floor: 110.58,
    };
  }

  if (instrument === "SMI") {
    return {
      ceiling: 11719.5,
      floor: 11719.49,
    };
  }

  if (instrument === "Bund") {
    return {
      ceiling: 133.7,
      floor: 133.69,
    };
  }

  if (instrument === "Bobl") {
    return {
      ceiling: 128.09,
      floor: 128.08,
    };
  }

  if (instrument === "BTP") {
    return {
      ceiling: 121.5,
      floor: 121.49,
    };
  }

  if (instrument === "OAT") {
    return {
      ceiling: 121.5,
      floor: 121.49,
    };
  }

  if (instrument === "Short BTP") {
    return {
      ceiling: 107.46,
      floor: 107.45,
    };
  }

  if (instrument === "Buxl") {
    return {
      ceiling: 135.41,
      floor: 135.39,
    };
  }

  return {
    ceiling: 0,
    floor: 0,
  };
};

export default mockedLivePrice;
