import ErrorCard from "@/components/ErrorCard";
import Skeleton from "@mui/material/Skeleton";
import ChildOrderCard from "./components/ChildOrderCard";
import { useState } from "react";
import { useAppSelector } from "@/store/hooks";
import { selectParent } from "../parent/parentSlice";
import { useGetInflightChildOrdersQuery } from "./childApiSlice";

export default function ChildOrders() {
  const selectedParent = useAppSelector(selectParent);
  const [expanded, setExpanded] = useState<number | false>(false);

  const handleChange = (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const { currentData, error, isError, isLoading, isSuccess, refetch } = useGetInflightChildOrdersQuery(
    selectedParent?.["parent-id"] || "",
    {
      skip: !selectedParent,
    }
  );

  if (isError) {
    return <ErrorCard error={error} refreshFn={refetch} />;
  }

  if (isLoading) {
    return <Skeleton height={120} width="100%" variant="rounded" />;
  }

  if (selectedParent && isSuccess && currentData) {
    return currentData.inflight.map((child, i) => (
      <ChildOrderCard
        key={child.id}
        parent={selectedParent}
        index={i}
        id={child.id}
        expanded={expanded}
        handleChange={handleChange}
      />
    ));
  }

  return null;
}
