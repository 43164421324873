import { useAppDispatch } from "@/store/hooks";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { takeRight } from "lodash";
import { PropsWithChildren, useEffect, useState } from "react";
import { TransitionGroup } from "react-transition-group";
import { ParentOrderResponse } from "../parentApiSlice";
import { setParentOrder } from "../parentSlice";
import ParentOrderCard from "./ParentOrderCard";

export interface ParentOrderCarouselProps extends PropsWithChildren {
  orders: ParentOrderResponse[];
}

export default function ParentOrderCarousel(props: ParentOrderCarouselProps) {
  const dispatch = useAppDispatch();
  const [visibleOrders, setVisibleOrders] = useState(takeRight(props.orders, 3));
  const [totalOrders, setTotalOrders] = useState(props.orders.length);
  // const [lastVisibleIndex, setLastVisibleIndex] = useState(totalOrders - 1);

  const [pagesCount, setPagesCount] = useState(props.orders.length - 2);
  const [pageNumber, setPageNumber] = useState(pagesCount);

  useEffect(() => {
    if (props.orders.length !== totalOrders) {
      handleCarousel("scroll-to-bottom");
      setTotalOrders(props.orders.length);
      setPagesCount(props.orders.length - 2);
      setPageNumber(props.orders.length - 2);
    }
  }, [props.orders.length]);

  const handleCarousel = (action: "scroll-up" | "scroll-down" | "scroll-to-bottom") => {
    // Force deselect any parent order
    dispatch(setParentOrder(null));

    // If adding a new parent order, always show it
    if (action === "scroll-to-bottom") {
      setVisibleOrders(takeRight(props.orders, 3));
      return;
    }

    // If there are less than 3 parent orders, then there's no scrolling required.
    if (visibleOrders.length !== 3 || props.orders.length < 3) return;

    const firstIndex = props.orders.findIndex((order) => order["parent-id"] === (visibleOrders[0] as any)["parent-id"]);
    const lastIndex = props.orders.findIndex((order) => order["parent-id"] === (visibleOrders[2] as any)["parent-id"]);
    const nextHiddenItem = action === "scroll-up" ? props.orders[firstIndex - 1] : props.orders[lastIndex + 1];

    if (action === "scroll-up") {
      setVisibleOrders((prev: any) => [
        nextHiddenItem,
        ...prev.filter((order: any) => order["parent-id"] !== (prev[2] as any)["parent-id"]),
      ]);
      // setLastVisibleIndex(lastIndex - 1);
      setPageNumber((prev) => prev - 1);
    } else {
      setVisibleOrders((prev: any) => [
        ...prev.filter((order: any) => order["parent-id"] !== (prev[0] as any)["parent-id"]),
        nextHiddenItem,
      ]);
      // setLastVisibleIndex(lastIndex + 1);
      setPageNumber((prev) => prev + 1);
    }
  };

  return (
    <Stack>
      <TransitionGroup>
        {visibleOrders.map((order) => (
          <Collapse timeout={400} key={order["parent-id"]}>
            <ParentOrderCard order={order} />
          </Collapse>
        ))}
      </TransitionGroup>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {props.children}

        {props.orders.length > 3 && (
          <Stack direction="row" alignItems="center">
            <Tooltip title="Scroll up">
              {/* Span required because the button can be disabled */}
              <span>
                <IconButton
                  size="small"
                  onClick={() => handleCarousel("scroll-up")}
                  disabled={(props.orders[0] as any)["parent-id"] === (visibleOrders[0] as any)["parent-id"]}
                >
                  <ExpandCircleDownOutlinedIcon sx={{ transform: "rotate(180deg)", height: 34, width: 34 }} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Scroll down">
              {/* Span required because the button can be disabled */}
              <span>
                <IconButton
                  size="small"
                  onClick={() => handleCarousel("scroll-down")}
                  disabled={
                    (props.orders[props.orders.length - 1] as any)["parent-id"] ===
                    (visibleOrders[2] as any)["parent-id"]
                  }
                >
                  <ExpandCircleDownOutlinedIcon sx={{ height: 34, width: 34 }} />
                </IconButton>
              </span>
            </Tooltip>
            {/* <Typography variant="caption" mx={1}>{`${lastVisibleIndex - 1} - ${
              lastVisibleIndex + 1
            } / ${totalOrders}`}</Typography> */}
            <Typography variant="caption" mx={1}>
              {`${pageNumber} / ${pagesCount}`}
            </Typography>
          </Stack>
        )}
      </Box>
    </Stack>
  );
}
