import { useAppSelector } from "@/store/hooks";
import { refetchTimeouts } from "@/utils/Constants";
import { selectInstrument } from "../instruments/instrumentSlice";
import { Offer, OfferType, useGetOfferIdsQuery, useGetOffersQuery } from "../offers/offersApiSlice";
import {
  ChildOrder,
  OrderState,
  useGetChildOrdersQuery,
  useGetInflightChildOrdersQuery,
} from "../orders/child/childApiSlice";
import { selectParent } from "../orders/parent/parentSlice";

export default function usePriceQueries() {
  const selectedParent = useAppSelector(selectParent);
  const selectedInstrument = useAppSelector(selectInstrument);

  const {
    currentData: inflightOffers,
    error: inflightOffersError,
    isError: isErrorOffersIds,
    isLoading: isLoadingOfferIds,
    isSuccess: isSuccessOfferIds,
    refetch: refetchOfferIds,
  } = useGetOfferIdsQuery(selectedInstrument || "", {
    skip: !selectedInstrument,
    pollingInterval: refetchTimeouts.fast,
  });

  const {
    currentData: offerData,
    error: offersError,
    isError: isErrorOffers,
    isLoading: isLoadingOffers,
    isSuccess: isSuccessOffers,
    refetch: refetchOffers,
  } = useGetOffersQuery(inflightOffers?.inflight || []);

  const {
    currentData: inflightChildren,
    error: inflightChildrenError,
    isError: isErrorInflightChildren,
    isSuccess: isSuccessInflightChildren,
    refetch: refetchInflightChildren,
  } = useGetInflightChildOrdersQuery(selectedParent?.["parent-id"] || "", {
    skip: !selectedParent,
  });

  const {
    currentData: childOrdersData,
    error: childOrdersError,
    isError: isErrorChildOrders,
    isSuccess: isSuccessChildOrders,
    refetch: refetchChildOrders,
  } = useGetChildOrdersQuery(inflightChildren?.inflight || []);

  /**
   * Returns either the best Bid or the best Ask (if there is one).
   */
  const getBestPrice = (type: OfferType, offers: Offer[], children: ChildOrder[]): number | undefined => {
    const offersOfType = offers.filter((offer) => offer["bid-ask"] === type);
    const workingChildren = children.filter((child) => child["bid-ask"] === type && child.state === OrderState.Working);
    // We can type children as offers because they also offers.
    const combinedOffers: any = [...offersOfType, ...(workingChildren as Offer[])];
    if (!combinedOffers.length) return undefined;
    let bestPrice = combinedOffers[0].price;
    combinedOffers.forEach((offer: any) => {
      if (type === OfferType.ASK) {
        bestPrice = bestPrice < offer.price ? bestPrice : offer.price;
      } else {
        bestPrice = bestPrice > offer.price ? bestPrice : offer.price;
      }
    });
    return bestPrice;
  };

  return {
    inflightOffers,
    inflightOffersError,
    isErrorOffersIds,
    isLoadingOfferIds,
    isSuccessOfferIds,
    refetchOfferIds,
    offerData,
    offersError,
    isErrorOffers,
    isLoadingOffers,
    isSuccessOffers,
    refetchOffers,
    inflightChildren,
    inflightChildrenError,
    isErrorInflightChildren,
    isSuccessInflightChildren,
    refetchInflightChildren,
    childOrdersData,
    childOrdersError,
    isErrorChildOrders,
    isSuccessChildOrders,
    refetchChildOrders,
    getBestPrice,
  };
}
