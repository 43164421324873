import { themeColors, themeVariants } from "@/app/theme";
import ErrorCard from "@/components/ErrorCard";
import { setInstrument } from "@/features/instruments/instrumentSlice";
import { setActiveRfq } from "@/features/notifications/notificationSlice";
import { setElectronicPrices, setPrice } from "@/features/prices/pricesSlice";
import { useBreakpoint } from "@/hooks/useBreakpoint";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { refetchTimeouts } from "@/utils/Constants";
import ClearIcon from "@mui/icons-material/Clear";
// import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import { numericFormatter } from "react-number-format";
import { matureStates, OrderState } from "../../child/childApiSlice";
import { ParentOrderResponse, useGetParentOrderQuery } from "../parentApiSlice";
import { selectParent, setParentOrder } from "../parentSlice";
import CancelParentOrder from "./CancelParentOrder";
import FlagIcon from "./FlagIcon";
import MoreDetails from "./MoreDetails";
import SendRfqDialog from "./SendRfqDialog";

export interface ParentOrderCardProps {
  order: ParentOrderResponse;
}

const FLAG_HEIGHT = 30.4;

export default function ParentOrderCard(props: ParentOrderCardProps) {
  const { isLargeOrUp } = useBreakpoint();

  const dispatch = useAppDispatch();
  const selectedParent = useAppSelector(selectParent);

  const [isActive, setIsActive] = useState(selectedParent?.["parent-id"] === props.order["parent-id"]);
  const hasNotifications = true; // TODO filter on alert events for those with the parent ID, but was still in discussion

  useEffect(() => {
    setIsActive(selectedParent?.["parent-id"] === props.order["parent-id"]);
  }, [selectedParent]);

  const { currentData, error, isError, isLoading, isSuccess, refetch } = useGetParentOrderQuery(
    props.order["parent-id"],
    {
      // Refetch parent data every minute to check for aggregation updates.
      // This is syncrhonised with the child update schedule.
      pollingInterval: refetchTimeouts.fast,
    }
  );

  const [isCancel, setIsCancel] = useState(false);
  const [isCancelable, setIsCancelable] = useState(false);

  useEffect(() => {
    if (currentData && currentData["parent-order-aggregation"]) {
      // If the status of this parent's child orders is any of that layed out in
      // the matureStates variable, then the parent is not cancellable.
      const childStates = Object.keys(
        currentData["parent-order-aggregation"]["child-orders-quantity-by-state"]
      ) as OrderState[];
      setIsCancelable(childStates.every((childState) => !matureStates.includes(childState)));
    }
    if (isActive && currentData) {
      // Make sure the store data for the selected parent is up-to-date.
      dispatch(setParentOrder(currentData));
    }
  }, [currentData]);

  // RFQ modal starts off closed
  const [rfqDialogOpen, setRfqDialogOpen] = useState(false);

  const closeTooltip = isCancelable && !isActive ? "Cancel order" : "Close";

  const handleClickPaper = () => {
    if (isCancel || !currentData) return;
    dispatch(setParentOrder(isActive ? null : currentData));
    if (!isActive) {
      // On select, set the underlying to == this parent's underlying.
      dispatch(setInstrument(currentData.underlying));
      // and force the RFQ response state to be falsy.
      dispatch(setActiveRfq(null));
    } else {
      // On deselct, reset the selected price.
      dispatch(setPrice(null));
      // When a parent card is deselected, the instrument selection dropdown must default back to its null value.
      dispatch(setInstrument(""));
      // Force the electronic prices to reset.
      dispatch(
        setElectronicPrices({
          floor: 0,
          ceiling: 0,
        })
      );
    }
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // Causes button click to select its parent card but not deselect it if already selected.
    if (isActive) {
      event.stopPropagation();
    }
    setRfqDialogOpen(true);
  };

  const handleCloseRfqDialog = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setRfqDialogOpen(false);
  };

  const handleCancelOrder = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (isActive) {
      // Deselect the card to trigger the collapse animation
      handleClickPaper();
    } else {
      setIsCancel(true);
    }
  };

  const { smallParentCard } = useBreakpoint();

  if (isError) {
    return <ErrorCard error={error} refreshFn={refetch} />;
  }

  if (isLoading) {
    return <Skeleton height={96} width="100%" variant="rounded" />;
  }

  if (isSuccess && currentData) {
    const remaining = (currentData["parent-order-aggregation"]?.["remaining-total"] || 0).toString();

    const working = (
      currentData["parent-order-aggregation"]?.["child-orders-quantity-by-state"]?.WORKING || "---"
    ).toString();

    const matched = (
      currentData["parent-order-aggregation"]?.["child-orders-quantity-by-state"]?.MATCHED || "---"
    ).toString();

    return (
      <Paper
        data-testid={`parent-order-${props.order["parent-id"]}`}
        elevation={1}
        sx={{
          "borderRadius": isLargeOrUp ? 4 : 2,
          "border": !isActive ? `0.3px solid ${themeColors.border}` : null,
          "py": 1,
          "pl": 2,
          "pr": 2,
          "mb": 2,
          "mx": 1,
          "backgroundColor": isCancel
            ? themeColors.white.secondary
            : isActive
            ? themeVariants.selected.backgroundColor
            : themeVariants.unselected.backgroundColor,
          "color": isActive ? themeVariants.selected.color : themeVariants.unselected.color,
          "transitionDuration": 400,
          "transitionTimingFunction": "ease",
          "transitionProperty": "all",
          "cursor": "pointer",
          "&:hover": {
            backgroundColor: !isActive && !isCancel ? themeColors.white.secondary : null,
          },
        }}
        onClick={() => handleClickPaper()}
      >
        {isCancel ? (
          <CancelParentOrder handleClose={() => setIsCancel(false)} id={props.order["parent-id"]} />
        ) : smallParentCard ? (
          <Stack sx={{ p: 0, ml: -1, mr: 1 }}>
            <Stack direction="row">
              <FlagIcon height={FLAG_HEIGHT} underlying={currentData.underlying} />
              <Typography fontSize={18} sx={{ flex: 1, ml: 1, mb: -1 }}>
                {currentData.underlying} - £{numericFormatter(String(currentData.quantity), { thousandSeparator: "," })}
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ mt: 1, mb: 1 }}>
              <Typography fontSize={10} sx={{ flex: 1, mr: 1, mb: -1 }}>
                Remaining: {numericFormatter(String(remaining), { thousandSeparator: "," })}
              </Typography>
              <Typography fontSize={10} sx={{ flex: 1, mr: 1, mb: -1 }}>
                Working: {numericFormatter(String(working), { thousandSeparator: "," })}
              </Typography>
              <Typography fontSize={10} sx={{ flex: 1, mb: -1 }}>
                Matched: {numericFormatter(String(matched), { thousandSeparator: "," })}
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <Stack sx={{ height: 110 }}>
            <Stack direction="row" sx={{ alignItems: "center" }} justifyContent="space-between" mb={1}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ display: isLargeOrUp ? "block" : "none" }} pt={1} mr={1}>
                  <FlagIcon height={FLAG_HEIGHT} underlying={currentData.underlying} />
                </Box>
                <Box>
                  <Typography fontSize={18} fontWeight={600} sx={{ flex: 1, mb: 0 }}>
                    {currentData.underlying}
                  </Typography>
                  <Typography fontSize={12}>{currentData.term}</Typography>
                </Box>
              </Box>
              <Stack direction="row" alignItems="center" spacing={1}>
                {isActive && currentData?.["rfq-client-name"] === undefined && (
                  <>
                    <Button
                      startIcon={<img src="./send-rfq.svg" />}
                      variant="outlined"
                      onClick={handleButtonClick}
                      color={isActive ? "rfqLight" : "rfqDark"}
                      sx={{ fontSize: 12, height: 25, minWidth: 10, paddingRight: 1 }}
                    >
                      {isLargeOrUp ? "Send RFQ" : null}
                    </Button>
                    <SendRfqDialog parent={currentData} open={rfqDialogOpen} onClose={handleCloseRfqDialog} />
                  </>
                )}
                {!!currentData?.["rfq-client-name"] && (
                  <Typography variant="caption">{`Client: ${currentData["rfq-client-name"]}`}</Typography>
                )}
                {/* {hasNotifications && (
                  <Badge role="notifications-badge" variant="dot" overlap="circular" color="secondary">
                    <NotificationsOutlinedIcon />
                  </Badge>
                )} */}
                {(isCancelable || isActive) && (
                  <Tooltip title={closeTooltip} placement="top">
                    <IconButton onClick={handleCancelOrder} sx={{ height: 28, width: 28 }}>
                      <ClearIcon
                        sx={{
                          height: 20,
                          width: 20,
                          color: isActive ? themeVariants.selected.color : themeVariants.unselected.color,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            </Stack>
            <Stack height="100%" justifyContent="space-between" direction="row">
              <Stack direction="row" mr={2}>
                <Stack>
                  <Typography variant="caption" mb={0.5}>
                    Side
                  </Typography>
                  <Typography variant="body1" fontSize={20} fontWeight={500}>
                    {capitalize(currentData.direction)}
                  </Typography>
                </Stack>
                <Stack ml={4}>
                  <Typography variant="caption" mb={0.5}>
                    Quantity
                  </Typography>
                  <Typography variant="numeric_body1" fontSize={20} fontWeight={500}>
                    {numericFormatter(String(currentData.quantity), {
                      thousandSeparator: ",",
                    })}
                  </Typography>
                </Stack>
              </Stack>
              <Stack>
                <MoreDetails parent={currentData} />
              </Stack>
            </Stack>
          </Stack>
        )}
      </Paper>
    );
  }

  return null;
}
