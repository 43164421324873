import dayjs from "@/utils/Time";
import { useEffect, useState } from "react";

export default function useTimer(timeRemaining: number) {
  const deadline = dayjs().add(timeRemaining, "s");

  const getTime = () => {
    const time = deadline.diff(); // Difference to current time in ms.
    const hours = Math.max(Math.floor((time / 1000 / 60 / 60) % 24), 0);
    const hh = formatTime(hours);
    const minutes = Math.max(Math.floor((time / 1000 / 60) % 60), 0);
    const mm = formatTime(minutes);
    const seconds = Math.max(Math.floor((time / 1000) % 60), 0);
    const ss = formatTime(seconds);
    return hours > 0 ? `${hh}:${mm}:${ss}` : `${mm}:${ss}`;
  };

  const formatTime = (time: number) => {
    return time > 9 ? time.toString() : `0${time.toString()}`;
  };

  const [timer, setTimer] = useState(getTime());
  const [isTimerDone, setIsTimerDone] = useState(false);

  useEffect(() => {
    setTimer(getTime());
    const interval = setInterval(() => {
      const timer = getTime();
      setTimer(timer);
      setIsTimerDone(timer === "00:00");
    }, 1000);
    return () => clearInterval(interval);
  }, [timeRemaining]);

  return { timer, isTimerDone };
}
