import { buildQuery } from "@/config/BaseQuery";
import { createAppSlice } from "@/store/createAppSlice";
import { LocalStorageKeys } from "@/types/LocalStorageKeys";
import { OAuthPostBody } from "@/types/OAuth";
import type { User } from "@/types/User";
import axios, { isAxiosError } from "axios";
import { PasswordResetPatchBody } from "@/types/PasswordReset";

export interface AuthState {
  token: string;
  user: User | null;
  status: "idle" | "loading" | "failed";
  errorCode: number | null;
  success: boolean | null;
}

const initialState: AuthState = {
  token:
    localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN) || sessionStorage.getItem(LocalStorageKeys.ACCESS_TOKEN) || "",
  user: null,
  status: "idle",
  errorCode: null,
  success: null,
};

export const authSlice = createAppSlice({
  name: "auth",
  initialState,
  reducers: (create) => ({
    handleLogin: create.asyncThunk(
      async (payload: OAuthPostBody, { rejectWithValue }) => {
        try {
          const response = await axios.post<string>(buildQuery("/users/login"), {
            username: payload.username,
            password: payload.password,
          });
          return { token: response.data, isPersistent: payload.isPersistent };
        } catch (error) {
          if (isAxiosError(error)) {
            return rejectWithValue(error.response?.status || null);
          } else {
            return rejectWithValue(null);
          }
        }
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          state.status = "idle";
          state.token = action.payload.token;
          // Does the user want to stay logged in? Then use localStorage.
          // If not, then use sessionStorage.
          if (action.payload.isPersistent) {
            localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, action.payload.token);
          } else {
            sessionStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, action.payload.token);
          }
          state.errorCode = null;
        },
        rejected: (state, action) => {
          state.status = "failed";
          state.errorCode = action.payload as number | null;
        },
      }
    ),
    handlePasswordReset: create.asyncThunk(
      async (payload: PasswordResetPatchBody, { rejectWithValue }) => {
        try {
          const response = await axios.patch<string>(buildQuery("/users/password"), {
            username: payload.username,
            password: payload.password,
            hash: payload.hash,
          });
          return { token: response.data };
        } catch (error) {
          if (isAxiosError(error)) {
            return rejectWithValue(error.response?.status || null);
          } else {
            return rejectWithValue(null);
          }
        }
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          state.status = "idle";
          state.success = true;
          state.token = action.payload.token;
          // Just use sessionStorage as not prompted to persist session
          sessionStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, action.payload.token);
          state.errorCode = null;
        },
        rejected: (state, action) => {
          state.status = "failed";
          state.errorCode = action.payload as number | null;
        },
      }
    ),
    handleLogout: create.reducer((state) => {
      localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN);
      sessionStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN);
      state.token = "";
      state.user = null;
    }),
    fetchUser: create.asyncThunk(
      async (token: string, { rejectWithValue }) => {
        try {
          const response = await axios.get<User>(buildQuery("/users/my-details"), {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          return response.data;
        } catch (error) {
          if (isAxiosError(error)) {
            return rejectWithValue(error.response?.status || null);
          } else {
            return rejectWithValue(null);
          }
        }
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          state.status = "idle";
          state.user = action.payload;
        },
        rejected: (state, action) => {
          state.status = "failed";
          state.errorCode = action.payload as number | null;
        },
      }
    ),
  }),
  selectors: {
    selectAuthToken: (auth) => auth.token,
    selectAuthUser: (auth) => auth.user,
    selectAuthStatus: (auth) => auth.status,
    selectCallSuccess: (auth) => auth.success,
    selectAuthErrorCode: (auth) => auth.errorCode,
  },
});

export const { handleLogin, handleLogout, fetchUser, handlePasswordReset } = authSlice.actions;

export const { selectAuthToken, selectAuthUser, selectAuthStatus, selectAuthErrorCode, selectCallSuccess } =
  authSlice.selectors;
