import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { AxiosError } from "axios";

export interface ErrorCardProps {
  error: FetchBaseQueryError | SerializedError | AxiosError | Error | undefined;
  refreshFn?: () => void;
}

const defaultMessage = "An unknown error has occured.";

export default function ErrorCard(props: ErrorCardProps) {
  const Message = () => {
    if (!props.error) return <Typography variant="body1">{defaultMessage}</Typography>;
    if ("data" in props.error) {
      // Then it was a FetchBaseQueryError from RTK-Query
      return <Typography variant="body1">{`[${props.error.status}]: ${JSON.stringify(props.error.data)}`}</Typography>;
    }
    if (props.error instanceof AxiosError) {
      return <Typography variant="body1">{JSON.stringify(props.error.toJSON())}</Typography>;
    }
    if ("message" in props.error) {
      // Then it's a JS error or a Serialized error
      return <Typography variant="body1">{props.error.message || defaultMessage}</Typography>;
    }
    return null;
  };

  return (
    <Card sx={{ minWidth: 275, m: 1 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Oops! There was an error.
        </Typography>
        <Message />
      </CardContent>
      {props.refreshFn && (
        <CardActions>
          <Button size="small" onClick={props.refreshFn}>
            Retry
          </Button>
        </CardActions>
      )}
    </Card>
  );
}
