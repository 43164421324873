import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Login from "@/features/login/Login";
import Dashboard from "@/features/dashboard/Dashboard";
import RootLayout from "@/components/RootLayout";
import PageNotFound from "@/components/PageNotFound";
import AccountInfo from "@/features/dashboard/components/AccountInfo";
import ChangePassword from "@/features/ChangePassword/ChangePassword";

/**
 * This is where all of the routes for the app are defined.
 * Each endpoint is associated with its own feature/ component.
 */
export default function Router() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      errorElement: <PageNotFound />,
      children: [
        // If the user removes the URL, redirect to the "index" route,
        // which in this case is '/dashboard'.
        // As the <Dashboard /> component is nested within <PrivateRoute />
        // the user will be redirected to <Login /> if they are not authenticated.
        {
          index: true,
          element: <Navigate to="/dashboard" replace />,
        },
        // Public Routes
        {
          path: "/login",
          element: <Login />,
        },
        // Public Routes
        {
          path: "/password",
          element: <ChangePassword />,
        },
        // Private Routes (i.e. routes that require authentication)
        {
          element: <PrivateRoute />,
          children: [
            {
              path: "/dashboard",
              element: <Dashboard />,
            },
            {
              path: "/user/account",
              element: <AccountInfo />,
            },
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}
