import { generateHash } from "./String";

const normalizeHash = (hash: number, min: number, max: number) => {
  return Math.floor((hash % (max - min)) + min);
};

const hRange: [number, number] = [0, 360];
const sRange: [number, number] = [50, 75];
const lRange: [number, number] = [25, 60];

export type HSL = [number, number, number];

export const generateHSL = (name: string): HSL => {
  const hash = generateHash(name);
  const h = normalizeHash(hash, hRange[0], hRange[1]);
  const s = normalizeHash(hash, sRange[0], sRange[1]);
  const l = normalizeHash(hash, lRange[0], lRange[1]);
  return [h, s, l];
};

export const HSLtoString = (hsl: HSL) => {
  return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

export const stringToColour = (input: string): string => {
  return HSLtoString(generateHSL(input));
};
