/**
 * Data saved in the browser's local storage has to be set/ retrived
 * with a string key.
 *
 * Those keys are enumerated here to reduce human error.
 */
export enum LocalStorageKeys {
  ACCESS_TOKEN = "access-token",
  REFRESH_TOKEN = "refresh-token",
}
