import { baseApi } from "@/config/BaseQuery";
import { Direction } from "../orders/parent/parentApiSlice";

export interface MatchRequest {
  "type": MatchRequestType;
  "match-request-id": string;
  "price": number;
  "residual": boolean;
  "offering": {
    "client-name": string;
    "quantity": number;
  };
  "requesting": {
    "client-name": string;
    "quantity": number;
  };
  "side": Direction;
  "created-at": number;
  "seconds-to-expiration": number;
}

export enum MatchRequestType {
  MATCH_REQUEST = "MatchRequest",
}

const matchRequestApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getMatchRequests: build.query<MatchRequest[], void>({
      query: () => "/notifications",
      providesTags: ["MatchRequestNotifications"],
      transformResponse: (response: MatchRequest[], meta, arg) => {
        // Sort response such that those closest to expiration are first.
        return response.sort((a, b) => a["seconds-to-expiration"] - b["seconds-to-expiration"]);
      },
    }),
  }),
});

export const { useGetMatchRequestsQuery } = matchRequestApiSlice;
